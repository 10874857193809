var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section"
  }, [_c('div', {
    staticClass: "container text-center"
  }, [_c('h2', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('home.partners.title')) + " ")]), _c('h6', {
    staticClass: "body-paragraph-bold-to-custom-to-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('home.partners.platform-liquidity-partners')) + " ")]), _vm._m(0)])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "row px-md-5"
  }, [_c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 d-flex align-items-center justify-content-center"
  }, [_c('a', {
    attrs: {
      "href": "https://www.metaquotes.net",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../svg/partner/metaquotes.svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 d-flex align-items-center justify-content-center"
  }, [_c('a', {
    attrs: {
      "href": "https://www.equiti.com/sc-en",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../svg/partner/equity.svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 d-flex align-items-center justify-content-center"
  }, [_c('a', {
    attrs: {
      "href": "https://www.swissquote.com/en-row/private",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../svg/partner/Swissquote.svg"),
      "alt": ""
    }
  })])]), _c('div', {
    staticClass: "col-12 col-lg-3 col-md-6 d-flex align-items-center justify-content-center"
  }, [_c('a', {
    attrs: {
      "href": "https://www.tradingcentral.com",
      "target": "_blank"
    }
  }, [_c('img', {
    attrs: {
      "src": require("../svg/partner/TradingCentral.svg"),
      "alt": ""
    }
  })])])]);
}]

export { render, staticRenderFns }