var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.66536 2.50098C2.1256 2.50098 2.4987 2.87407 2.4987 3.33431V15.001C2.4987 15.4612 2.87179 15.8343 3.33203 15.8343H18.332C18.7923 15.8343 19.1654 16.2074 19.1654 16.6676C19.1654 17.1279 18.7923 17.501 18.332 17.501H3.33203C1.95132 17.501 0.832031 16.3817 0.832031 15.001V3.33431C0.832031 2.87407 1.20513 2.50098 1.66536 2.50098Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17.0814 4.27791C17.481 4.50625 17.6199 5.0153 17.3915 5.41489L14.7361 10.0619C14.1448 11.0966 12.9055 11.5701 11.7749 11.1933L9.17365 10.3262C8.84243 10.2158 8.47751 10.3235 8.2594 10.5962L5.65206 13.8554C5.36455 14.2147 4.84014 14.273 4.48075 13.9855C4.12137 13.698 4.0631 13.1736 4.35061 12.8142L6.95796 9.55502C7.61226 8.73713 8.70705 8.41383 9.7007 8.74505L12.302 9.61213C12.6788 9.73775 13.0919 9.57991 13.289 9.23501L15.9445 4.588C16.1728 4.1884 16.6819 4.04957 17.0814 4.27791Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }