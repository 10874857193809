var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10 11.4255C6.77834 11.4255 4.16667 14.0371 4.16667 17.2588V18.9255C4.16667 19.3857 3.79357 19.7588 3.33333 19.7588C2.8731 19.7588 2.5 19.3857 2.5 18.9255V17.2588C2.5 13.1167 5.85786 9.75879 10 9.75879C14.1421 9.75879 17.5 13.1167 17.5 17.2588V18.9255C17.5 19.3857 17.1269 19.7588 16.6667 19.7588C16.2064 19.7588 15.8333 19.3857 15.8333 18.9255V17.2588C15.8333 14.0371 13.2217 11.4255 10 11.4255Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10 9.75814C11.8409 9.75814 13.3333 8.26575 13.3333 6.42481C13.3333 4.58386 11.8409 3.09147 10 3.09147C8.15905 3.09147 6.66667 4.58386 6.66667 6.42481C6.66667 8.26575 8.15905 9.75814 10 9.75814ZM10 11.4248C12.7614 11.4248 15 9.18623 15 6.42481C15 3.66338 12.7614 1.4248 10 1.4248C7.23858 1.4248 5 3.66338 5 6.42481C5 9.18623 7.23858 11.4248 10 11.4248Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }