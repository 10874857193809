var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "mouseover": function ($event) {
        _vm.hover = true;
      },
      "mouseleave": function ($event) {
        _vm.hover = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.6492 11.6665C1.2711 11.6704 0.381504 13.2403 1.2305 14.4298C2.44635 16.133 4.65114 17.5 8.33199 17.5C14.0362 17.5 18.6151 12.8514 17.9741 7.35925L18.9132 5.48108C19.5644 4.17857 18.4327 2.69685 17.0047 2.98245L15.7641 3.23057C15.4352 3.05702 15.0947 2.92606 14.8038 2.83117C14.2368 2.64627 13.5506 2.5 12.9153 2.5C11.7742 2.5 10.7894 2.79276 10.0067 3.37994C9.23299 3.96053 8.79716 4.72354 8.55799 5.42421C8.44774 5.74717 8.37433 6.07112 8.32722 6.38203C7.88449 6.24269 7.43307 6.05522 6.99112 5.82629C5.98845 5.30693 5.17766 4.64852 4.72028 4.07126C3.95293 3.10275 2.32673 3.17456 1.75608 4.43351C0.951793 6.20789 1.17242 8.31122 1.89537 10.0108C2.13768 10.5803 2.45289 11.1434 2.83798 11.6652C2.77206 11.666 2.70899 11.6663 2.6492 11.6665ZM8.33191 15.8333C5.11246 15.8333 3.44773 14.6672 2.58694 13.4614C2.54827 13.4072 2.58727 13.3333 2.65383 13.3332C3.52953 13.3307 5.32703 13.2892 6.51253 12.5954C6.57383 12.5595 6.56034 12.4691 6.49301 12.4465C3.72953 11.5173 2.15868 7.58216 3.27399 5.12158C3.29957 5.06517 3.3754 5.05772 3.41388 5.10628C4.68793 6.71432 7.4736 8.28933 9.89724 8.33242C9.94983 8.33333 9.98958 8.28558 9.98158 8.23361C9.88391 7.60044 9.51099 4.16667 12.9152 4.16667C13.7282 4.16667 14.9377 4.56319 15.3832 4.96942C15.4037 4.98819 15.4317 4.99672 15.459 4.99125L17.3315 4.61675C17.3995 4.60315 17.4534 4.67371 17.4224 4.73573L16.261 7.05849C16.2529 7.07462 16.2504 7.09312 16.2535 7.11088C17.0669 11.6833 13.3219 15.8333 8.33191 15.8333Z",
      "fill": _vm.hover ? '#07B53B' : '#212529'
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }