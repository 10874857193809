<template>
  <div class="position-relative">
    <div class="container">
      <div class="row">
        <div class="col-12 p-0">
          <Hero />
          <MarketTrends class="homeMarketTrends" />
          <TradingTime />
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <AccountTypes />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-12 p-0">
          <Partners />
          <GetStarted />
          <AreYouReady class="a" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Hero from "./components/sections/Hero.vue";
import MarketTrends from "@/components/market-trends/MarketTrends.vue";
import TradingTime from "./components/sections/TradingTime.vue";
import AccountTypes from "./components/sections/AccountTypes.vue";
import Partners from "./components/sections/Partners.vue";
import GetStarted from "./components/sections/GetStarted.vue";
import AreYouReady from "@/components/are-you-ready/AreYouReady.vue";
export default {
  components: {
    Hero,
    MarketTrends,
    TradingTime,
    AccountTypes,
    GetStarted,
    AreYouReady,
    Partners
  },
  data() {
    return {
      autoJump: false,
    };
  },
  metaInfo: {
    title: 'NOZAX: Regulated multi-asset neo-broker',
    meta: [
      { name: 'description', content: 'Put your money in motion with a regulated broker! Feel the power of technology, and join the future of trading today.' },
    ]
  }
};
</script>

<style lang="scss" scoped>
.a {
  @media screen and (max-width: 767px) {
    margin-top: 41px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 50px;
    margin-bottom: 77px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 108px;
    margin-bottom: 64px;
  }
}
.homeMarketTrends {
  @media (min-width: 1200px) {
    margin-top: 40px !important;
  }
}

.myAbsDiv {
  @media (min-width: 1200px) {
    margin-top: 550px;
    margin-left: -720px;
    left: 50%;
    top: 50%;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    margin-top: 520px;
    margin-left: -720px;
    left: 50%;
    top: 50%;
  }
  @media (max-width: 767px) {
    margin-top: -30px;
    margin-left: -190px;
    left: 50%;
    top: 50%;
  }
}
.bfrAT {
  @media (min-width: 1200px) {
    margin-bottom: 820px;
  }
  @media (max-width: 1199px) and (min-width: 768px) {
    margin-bottom: 765px;
  }
  @media (max-width: 767px) {
    margin-bottom: 1185px;
  }
}
</style>
