<template>
  <div class="section trading position-relative">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 p-0">
          <h2 class="trading-title text-center light-theme-title">
            {{ $t("home.trading time.title") }}
          </h2>
          <h4 class="text-center subtitle-bodyLarge text-grey-primary-main-500">
            {{ $t("home.trading time.sub-title") }}
          </h4>
          <div class="position-absolute d-none d-md-block" style="top: 18%">
            <HomeTradingIcon />
          </div>
          <div
            class="d-md-none position-absolute"
            style="top: 29%; left: 30%; z-index: 5"
          >
            <HomeMobileTradingIcon />
          </div>
          <div class="trading-items">
            <div
              class="trading-item position-relative paddingSwitchByLang"
              id="re"
            >
              <h3 class="light-theme-title">
                {{ $t("home.trading time.register") }}
              </h3>
              <div class="d-flex" style="gap: 14px">
                <div style="width: 25px; height: 25px">
                  <CheckRoundedIcon />
                </div>
                <p
                  class="trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 re"
                >
                  {{ $t("home.trading time.register-text") }}
                </p>
                <div class="mark-wrap">
                  <div class="mark" style=""></div>
                </div>
              </div>
            </div>

            <div
              class="trading-item position-relative paddingSwitchByLang"
              id="expert"
            >
              <h3 class="light-theme-title">
                {{ $t("home.trading time.learn") }}
              </h3>
              <div class="d-flex" style="gap: 14px">
                <div style="width: 25px; height: 25px">
                  <CheckRoundedIcon />
                </div>
                <p
                  class="trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 le"
                >
                  {{ $t("home.trading time.learn-text") }}
                </p>
              </div>
              <div class="mm-wrap">
                <div class="mm">
                  <!-- <img src="@/assets/icons/dialog_right.png" alt="" /> -->
                </div>
              </div>
            </div>

            <div
              class="trading-item position-relative paddingSwitchByLang"
              id="set"
            >
              <h3 class="light-theme-title">
                {{ $t("home.trading time.setup") }}
              </h3>
              <div class="d-flex" style="gap: 14px">
                <div style="width: 25px; height: 25px">
                  <CheckRoundedIcon />
                </div>

                <p
                  class="trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 set"
                >
                  {{ $t("home.trading time.setup-text") }}
                </p>
              </div>
              <div class="mark-wrap">
                <div class="mark"></div>
              </div>
            </div>

            <div
              class="trading-item position-relative paddingSwitchByLang"
              id="go"
            >
              <h3 class="light-theme-title">
                {{ $t("home.trading time.trade") }}
              </h3>
              <div class="d-flex" style="gap: 14px">
                <div style="width: 25px; height: 25px">
                  <CheckRoundedIcon />
                </div>
                <p
                  class="trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 tr"
                >
                  {{ $t("home.trading time.trade-text") }}
                </p>
              </div>
              <div class="mm-wrap">
                <div class="mm">
                  <!-- <img src="@/assets/icons/dialog_right.png" alt="" /> -->
                </div>
              </div>
            </div>
          </div>

          <a
            class="link d-flex justify-content-center"
            href="https://nozax.kb.help/how-do-i-open-an-account-with-nozax/"
            target="_blank"
          >
            <b-button
              variant="outline-primary"
              class="round-btn-16-but-17-24-outline my-but"
            >
              <p class="button-medium">
                {{ $t("home.trading time.learn more") }}
              </p>
            </b-button></a
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeTradingIcon from "../svg/trading/HomeTradingIcon.vue";
import HomeMobileTradingIcon from "../svg/trading/HomeMobileTradingIcon.vue";
import CheckRoundedIcon from "@/components/icons/CheckRoundedIcon.vue";
export default {
  components: {
    HomeTradingIcon,
    CheckRoundedIcon,
    HomeMobileTradingIcon,
  },
};
</script>
<style lang="scss" scoped>
.my-but {
  @media (max-width: 767px) {
    height: 48px !important;
  }
  // width: 168px !important;
  height: 50px !important;
}
h4 {
  @media screen and (max-width: 767px) {
    margin-top: 8px;
    margin-right: auto;
    margin-left: auto;
    max-width: 200px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 24px;
  }
}
.mark {
  padding: 0 !important;
  // position: absolute;
  width: 27px;
  height: 27px;
  background: #fff;
  /* background: red; */
  clip-path: polygon(0 0, 0 100%, 100% 0);

  // @media (max-width: 767px) {
  //   left: 20%;
  //   bottom: -19%;
  // }
  // @media (min-width: 768px) {
  //   bottom: -12%;
  //   right: 20%;
  // }
}
.mark-wrap {
  filter: drop-shadow(0px 8px 16px rgba(218, 225, 233, 0.56));
  position: absolute;
  width: 27px;
  height: 27px;
  @media (max-width: 767px) {
    left: 20%;
    bottom: -19%;
  }
  @media (min-width: 768px) {
    bottom: -12%;
    right: 20%;
  }
}
.mm {
  padding: 0 !important;
  width: 27px;
  height: 27px;
  background: #fff;
  clip-path: polygon(0 0, 100% 100%, 100% 0);
}
.mm-wrap {
  position: absolute;
  filter: drop-shadow(0px 8px 16px rgba(218, 225, 233, 0.56));
  @media (max-width: 767px) {
    bottom: -19%;
    right: 15%;
  }
  @media (min-width: 768px) {
    bottom: -12%;
    right: 20%;
  }
}
.trading {
  @media screen and (max-width: 767px) {
    margin-top: 101px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 50px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 68px;
    /* margin-top: 0px; */
  }

  .trading-items {
    display: grid;
    grid-gap: 90px 75px;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding: 100px 0;
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      grid-gap: 100px 32px !important;
      justify-content: center;
    }
    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0px !important;
      padding: 40px 0;
      #go {
        order: 2;
        margin-bottom: 69px !important;
      }
      #re {
        order: 1;
        margin-bottom: 66.41px !important;
      }
      #expert {
        order: 4;
      }
      #set {
        order: 3;
        margin-bottom: 68px !important;
      }
    }
    .trading-item {
      background: #ffffff;
      box-shadow: 0px 8px 16px rgba(218, 225, 233, 0.56);
      border-radius: 24px;
      padding: 20px 22px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      width: 413px;
      height: 195px;
      @media screen and (max-width: 768px) {
        max-width: 314px;
        height: 141px;
      }
      @media screen and (max-width: 826px) and (min-width: 768px) {
        width: calc((100vw - 32px) / 2);
        height: 195px;
      }
    }
  }
}

.paddingSwitchByLang {
  padding: 10px 22px !important;
}

h3 {
  @media (max-width: 767px) {
    font-weight: 500;
    font-size: 24px !important;
    line-height: 100%;
    /* or 24px */
  }
}
.body-paragraph-1-to-mobile-body-regular {
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}

.re {
  @media (max-width: 768px) {
    max-width: 218px !important;
  }
}
.tr {
  @media (max-width: 768px) {
    width: 240px !important;
  }
}
.set {
  @media (max-width: 768px) {
    width: 239px !important;
  }
}
.le {
  @media (max-width: 768px) {
    width: 244px !important;
  }
}
</style>
