var render = function () {
  var _vm$locales$find;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "navbar-container d-flex justify-content-center align-items-center"
  }, [_c('div', {
    staticClass: "main-nav"
  }, [_c('div', {
    staticClass: "d-flex align-items-center left-block"
  }, [_c('router-link', {
    staticClass: "link nav-logo d-flex",
    attrs: {
      "to": "/"
    }
  }, [_c('img', {
    staticClass: "nozax-logo",
    attrs: {
      "src": require("@/assets/Group.svg"),
      "alt": "brand-logo"
    }
  }), _c('div', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "background": "#e6e8ec",
      "width": "1px",
      "margin-left": "19px",
      "margin-right": "19px"
    }
  })]), _c('b-navbar-nav', {
    staticClass: "flex-row d-none d-xxl-flex justify-content-between",
    staticStyle: {
      "height": "38px",
      "gap": "30px"
    }
  }, [_c('b-nav-item', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.outside1,
      expression: "outside1"
    }],
    staticClass: "position-relative nav-padding-10",
    on: {
      "click": function ($event) {
        _vm.isMarketDropdownShowing = !_vm.isMarketDropdownShowing;
      },
      "mouseover": function ($event) {
        _vm.isLanguageOptionShowing = false, _vm.isCompanyDropdownShowing = false, _vm.isMarketDropdownShowing = true, _vm.isToolDropdownShowing = false;
      },
      "mouseleave": function ($event) {
        return _vm.delayCloseMarketDD();
      }
    }
  }, [_c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title d-inline-block light-theme-title greenHover",
    attrs: {
      "to": "/markets"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.markets.markets')) + " ")]), _c('ChevronDown', {
    staticStyle: {
      "width": "18px",
      "height": "18px",
      "margin-left": "10px"
    }
  }), _vm.isMarketDropdownShowing ? _c('div', {
    attrs: {
      "id": "market-dropdown"
    },
    on: {
      "mouseover": function () {
        return _vm.clearTimeout(_vm.myMarketTimeout);
      }
    }
  }, [_c('div', {
    staticClass: "arrow"
  }), _c('div', {
    staticClass: "links"
  }, _vm._l(_vm.navs, function (i, ind) {
    return _c('div', [_c('div', {
      staticClass: "d-flex justify-content-start align-items-center",
      class: _vm.marketType == i ? 'text-success' : '',
      staticStyle: {
        "width": "224px"
      },
      on: {
        "click": function ($event) {
          return _vm.goToMarket(i);
        }
      }
    }, [_c(i, {
      tag: "component",
      staticStyle: {
        "width": "20px",
        "height": "20px",
        "margin-right": "8px"
      }
    }), _c('p', {
      staticClass: "navigation-semi-bold light-theme-title greenHover"
    }, [_vm._v(" " + _vm._s(_vm.$t(("nav.markets." + (i.toLowerCase())))) + " ")])], 1), ind !== _vm.navs.length - 1 ? _c('div', {
      staticStyle: {
        "width": "100%",
        "height": "1px",
        "background": "#e6e8ec",
        "margin-bottom": "17px",
        "margin-top": "12px"
      }
    }) : _vm._e()]);
  }), 0)]) : _vm._e()], 1), _c('b-nav-item', {
    staticClass: "nav-padding-10"
  }, [_c('router-link', {
    staticClass: "link navigation-semi-bold",
    attrs: {
      "to": "/accounts-and-fees"
    }
  }, [_vm._v(_vm._s(_vm.$t('nav.accounts and fees')))])], 1), _c('b-nav-item', {
    staticClass: "nav-padding-10"
  }, [_c('router-link', {
    staticClass: "link navigation-semi-bold",
    attrs: {
      "to": "/trading-platform"
    }
  }, [_vm._v(_vm._s(_vm.$t('nav.trading platform')))])], 1), _c('b-nav-item', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.outside4,
      expression: "outside4"
    }],
    staticClass: "position-relative nav-padding-10",
    on: {
      "click": function ($event) {
        _vm.isToolDropdownShowing = !_vm.isToolDropdownShowing;
      },
      "mouseover": function ($event) {
        _vm.isLanguageOptionShowing = false, _vm.isToolDropdownShowing = true, _vm.isMarketDropdownShowing = false, _vm.isCompanyDropdownShowing = false;
      },
      "mouseleave": function ($event) {
        return _vm.delayCloseToolDD();
      }
    }
  }, [_c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title d-inline-block greenHover",
    attrs: {
      "to": "/tools"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.tools.tools')) + " ")]), _c('ChevronDown', {
    staticStyle: {
      "width": "18px",
      "height": "18px",
      "margin-left": "10px"
    }
  }), _vm.isToolDropdownShowing ? _c('div', {
    attrs: {
      "id": "tool-dropdown"
    },
    on: {
      "mouseover": function () {
        return _vm.clearTimeout(_vm.myToolTimeout);
      }
    }
  }, [_c('div', {
    staticClass: "arrow"
  }), _c('div', {
    staticClass: "links"
  }, [_c('router-link', {
    staticClass: "navigation-semi-bold",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/tools/economic-calendar"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('Calendar', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.tools.economic-calendar')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/tools/technical-views"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('TechnicalViews', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.tools.technical-views')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/tools/tc-alpha-generator"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('AlphaGenerator', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.tools.alpha-generator')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/tools/cp-widgets"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('Widgets', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.tools.cp-widgets')) + " ")])], 1)])], 1)]) : _vm._e()], 1), _c('b-nav-item', {
    staticClass: "nav-padding-10 navigation-semi-bold greenHover",
    attrs: {
      "href": "https://nozax.kb.help/",
      "target": "_blank"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.knowledge base')))]), _c('b-nav-item', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.outside2,
      expression: "outside2"
    }],
    staticClass: "position-relative nav-padding-10",
    on: {
      "click": function ($event) {
        _vm.isCompanyDropdownShowing = !_vm.isCompanyDropdownShowing;
      },
      "mouseover": function ($event) {
        _vm.isLanguageOptionShowing = false, _vm.isCompanyDropdownShowing = true, _vm.isMarketDropdownShowing = false, _vm.isToolDropdownShowing = false;
      },
      "mouseleave": function ($event) {
        return _vm.delayCloseCompanyDD();
      }
    }
  }, [_c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title d-inline-block greenHover",
    attrs: {
      "to": "/company"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.company')) + " ")]), _c('ChevronDown', {
    staticStyle: {
      "width": "18px",
      "height": "18px",
      "margin-left": "10px"
    }
  }), _vm.isCompanyDropdownShowing ? _c('div', {
    attrs: {
      "id": "company-dropdown"
    },
    on: {
      "mouseover": function () {
        return _vm.clearTimeout(_vm.myCompanyTimeout);
      }
    }
  }, [_c('div', {
    staticClass: "arrow"
  }), _c('div', {
    staticClass: "links"
  }, [_c('router-link', {
    staticClass: "navigation-semi-bold",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/company/about-us"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('AboutUs', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.about us')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/company/careers"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('Careers', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.careers')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/company/affiliates"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('Affiliates', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.affiliates')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/company/legal-documents"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('LegalDocumentation', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.legal documentation')) + " ")])], 1)]), _c('div', {
    staticStyle: {
      "width": "100%",
      "height": "1px",
      "background": "#e6e8ec",
      "margin-bottom": "17px",
      "margin-top": "12px"
    }
  }), _c('router-link', {
    staticClass: "navigation-semi-bold light-theme-title light-theme-title",
    staticStyle: {
      "text-decoration": "none",
      "width": "100%"
    },
    attrs: {
      "to": "/company/contact-us"
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center greenHover",
    staticStyle: {
      "width": "224px"
    }
  }, [_c('ContactUs', {
    staticStyle: {
      "width": "20px",
      "height": "20px",
      "margin-right": "8px"
    }
  }), _c('p', {
    staticClass: "navigation-semi-bold light-theme-title greenHover"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.company.contact us')) + " ")])], 1)])], 1)]) : _vm._e()], 1)], 1)], 1), _c('b-navbar-nav', {
    staticClass: "right-block d-md-flex flex-row align-items-center",
    staticStyle: {
      "height": "38px"
    }
  }, [_c('b-nav-item', {
    directives: [{
      name: "click-outside",
      rawName: "v-click-outside",
      value: _vm.outside3,
      expression: "outside3"
    }],
    staticClass: "position-relative nav-padding-12-16 d-flex align-items-center",
    staticStyle: {
      "height": "38px"
    },
    on: {
      "click": function ($event) {
        _vm.isLanguageOptionShowing = !_vm.isLanguageOptionShowing;
      },
      "mouseover": function ($event) {
        _vm.isLanguageOptionShowing = true, _vm.isCompanyDropdownShowing = false, _vm.isMarketDropdownShowing = false, _vm.isToolDropdownShowing = false;
      },
      "mouseleave": function ($event) {
        return _vm.delayCloseLanguageDD();
      }
    }
  }, [_c('span', {
    staticClass: "button-small d-inline-block light-theme-title text-uppercase"
  }, [_vm._v(" " + _vm._s(((_vm$locales$find = _vm.locales.find(function (l) {
    return l.code == _vm.$i18n.locale;
  })) === null || _vm$locales$find === void 0 ? void 0 : _vm$locales$find.code) || _vm.lan) + " ")]), _c('ChevronDown', {
    staticStyle: {
      "width": "32px",
      "height": "32px",
      "margin-left": "6px"
    }
  }), _vm.isLanguageOptionShowing ? _c('div', {
    attrs: {
      "id": "language-dropdown"
    },
    on: {
      "mouseover": function () {
        return _vm.clearTimeout(_vm.myLanguageTimeout);
      }
    }
  }, [_c('div', {
    staticClass: "arrow"
  }), _c('div', {
    staticClass: "lang-links"
  }, _vm._l(_vm.langs, function (lan, index) {
    var _vm$locales$find2;

    return _c('div', {
      key: index,
      staticClass: "d-flex justify-content-start align-items-center greenHover lan borderClass py-2",
      on: {
        "click": function ($event) {
          return _vm.setLang(lan);
        }
      }
    }, [_c('img', {
      staticStyle: {
        "margin-right": "8px"
      },
      attrs: {
        "src": _vm.getImgUrl(lan)
      }
    }), _c('p', {
      staticClass: "navigation-semi-bold light-theme-title greenHover"
    }, [_vm._v(" " + _vm._s(((_vm$locales$find2 = _vm.locales.find(function (l) {
      return l.code == lan;
    })) === null || _vm$locales$find2 === void 0 ? void 0 : _vm$locales$find2.label_en) || lan) + " ")]), _c('div', {
      staticStyle: {
        "height": "1px",
        "background": "#e6e8ec",
        "margin": "12px auto 17px"
      }
    })]);
  }), 0)]) : _vm._e()], 1), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-12-16 d-none d-md-block",
    staticStyle: {
      "margin-right": "8px"
    },
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-small text-white-button"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.sign up')) + " ")])]) : _vm._e(), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-12-16-outline d-none d-md-block",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToLogin();
      }
    }
  }, [_c('p', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.$t('nav.login')) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "d-xxl-none x-icon",
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  }, [!_vm.show ? _c('HamMenu', {
    staticClass: "w-100 h-100"
  }) : _c('XIcon', {
    staticClass: "w-100 h-100"
  })], 1)], 1)], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_vm.show ? _c('TabletAndMobileNavBar', {
    staticClass: "d-xxl-none list",
    attrs: {
      "show": _vm.show
    },
    on: {
      "update:show": function ($event) {
        _vm.show = $event;
      },
      "close": function ($event) {
        _vm.show = false;
      }
    }
  }) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }