import Vue from "vue";
import lodash from "lodash";

export default {
  state: () => ({
    site_settings: {},
    isCompanyFetched: false,
  }),
  mutations: {
    UPDATE_SITE_SETTINGS(state, { path_arr, settings }) {
      lodash.set(state, path_arr, settings);
    },
  },
  actions: {
    async fetchCompanySettings({ commit, state }) {
      const res = await Vue.prototype.$axios.get(`public_site_settings/company`);
      state.isCompanyFetched = true;
      commit("UPDATE_SITE_SETTINGS", {
        path_arr: ["site_settings", "company"],
        settings: res.data.settings,
      });
    },
  },
  getters: {
    isCompanyFetched(state) {
      return state.isCompanyFetched;
    },
    companySettings(state) {
      return state.site_settings?.company;
    },
    getSiteSettings(state) {
      console.log("State :", state);
      return (child_path_arr, settings_path_arr) => {
        let settings = state;
        child_path_arr.forEach((item) => {
          settings = settings?.children?.[item] ?? settings?.[item];
        });
        if (!settings_path_arr) return settings;
        return lodash.get(settings, settings_path_arr, null);
      };
    },
  },
};
