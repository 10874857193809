<template>
  <div>
    <b-table
      :items="computed_symbols_data"
      :fields="fields"
      class="table table-borderless table-text align-middle"
      thead-class="header table-header"
      tbody-tr-class="body-row"
      thead-tr-class="head-row"
      primary-key="symbol"
    >
      <!-- Optional default data cell scoped slot -->

      <template #cell(symbol-icon)="{ item }">
        <b-img class="icon mr-symbol" :src="getIconForSymbol(item)" />
      </template>

      <template #cell(symbol-name)="{ item }">
        <div class="d-flex flex-column justify-content-center">
          <span
            class="truncate"
            v-if="
              item.group.startsWith('CFDs on Stocks') ||
              item.group.startsWith('Indices')
            "
          >
            {{ item.long_name }}
          </span>
          <span class="truncate" v-else>
            {{ item.symbol }}
          </span>
          <span
            class="small text-muted"
            v-if="
              item.group.startsWith('CFDs on Stocks') ||
              item.group.startsWith('Indices')
            "
          >
            {{ item.symbol }}
          </span>
        </div>
      </template>

      <template #cell(chartData)="data">
        <Chart :chartData="data.value" />
      </template>
      <template #cell(configuration.SwapLong)="data">
        <span>
          {{ (+data.value).toFixed(2) }}
        </span>
      </template>
      <template #cell(configuration.SwapShort)="data">
        <span>
          {{ (+data.value).toFixed(2) }}
        </span>
      </template>
      <template #cell(Bid)="data">
        <span>
          {{ data.value.toString() }}
        </span>
      </template>
      <template #cell(Ask)="data">
        <span>
          {{ data.value.toString() }}
        </span>
      </template>
      <template #cell(Spread)="data">
        <span>
          {{ (+data.value).toString() }}
        </span>
      </template>
    </b-table>
  </div>
</template>

<script>
import Chart from "./Chart.vue";
import { getSymbolType, getIconForSymbol } from "./marketTrendsTypes";
import { mapState } from "vuex";
export default {
  name: "newest-trends",
  components: { Chart },
  props: {
    nowUrl: String,
    symbols: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    getSymbolType,
    getIconForSymbol,
  },

  computed: {
    ...mapState({
      symbol_long_name: (state) => state.symbol_long_name,
    }),
    computed_symbols_data() {
      return this.symbols.map((n) => ({
        // chart: Chart,
        ...n,
        chartData: n?.chartData || [],
        Bid: (n?.bid || 0).toFixed(n?.digits),
        Ask: (n?.ask || 0).toFixed(n?.digits),
        Spread: n?.group?.startsWith("Forex")
          ? ((n?.ask - n?.bid) * 10 ** (n?.digits - 1) || 0).toFixed(n?.digits)
          : (n?.ask - n?.bid || 0).toFixed(n?.digits),
      }));
    },
    fields() {
      return [
        {
          key: "symbol-icon",
          thClass: "markets-symbol-icon",
          tdClass: "markets-symbol-icon",
          label: `${this.$t("components.market trend.table-header.markets")}`,
        },
        {
          key: "symbol-name",
          thClass: "markets-symbol-name",
          tdClass: "markets-symbol-name",
          label: ``,
        },
        {
          key: "Bid",
          tdClass: "sell table-red",
          // thClass: " body-paragraph-1 text-center light-theme-title",
          label: `${this.$t("components.market trend.table-header.sell")}`,
        },
        {
          key: "Ask",
          tdClass: "buy text-primary",
          // thClass: " body-paragraph-1 text-center light-theme-title",
          label: `${this.$t("components.market trend.table-header.buy")}`,
        },
        {
          key: "Spread",
          tdClass: "spread",
          // "align-middle text-center neutrals-4-to-light-theme-title-text body-paragraph-14",
          // thClass: " body-paragraph-1 text-center light-theme-title",
          label: `${this.$t("components.market trend.table-header.spread")}`,
        },
        {
          key: "chartData",
          class: this.nowUrl == "" ? "d-none d-md-table-cell" : "d-none ",
          // class: "d-none ",
          label: `${this.$t("components.market trend.table-header.chart")}`,
        },
        {
          key: "configuration.SwapLong",
          class:
            this.nowUrl == "" ? "d-none " : "d-none d-md-table-cell longSwap",

          label: `${this.$t("components.market trend.table-header.longSwap")}`,
        },
        {
          key: "configuration.SwapShort",
          class:
            this.nowUrl == "" ? "d-none " : "d-none d-md-table-cell shortSwap",

          label: `${this.$t("components.market trend.table-header.shortSwap")}`,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
/* @media (max-width: 767px) {
  ::v-deep .table > tbody > tr > td {
    padding: 5px;
  }
} */
.small {
  @media (min-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 767px) {
    font-size: 10px;
  }
}
.mr-symbol {
  width: 32px;
  height: 32px;
  @media (min-width: 768px) {
    margin-right: 16px !important;
  }
  @media (max-width: 767px) {
    margin-right: 15px !important;
  }
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
