var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.33474 8.33474C8.78105 7.88842 9.50467 7.88842 9.95098 8.33474L16 14.3838L22.049 8.33474C22.4953 7.88842 23.219 7.88842 23.6653 8.33474C24.1116 8.78105 24.1116 9.50467 23.6653 9.95098L17.6162 16L23.6653 22.049C24.1116 22.4953 24.1116 23.219 23.6653 23.6653C23.219 24.1116 22.4953 24.1116 22.049 23.6653L16 17.6162L9.95098 23.6653C9.50467 24.1116 8.78105 24.1116 8.33474 23.6653C7.88842 23.219 7.88842 22.4953 8.33474 22.049L14.3838 16L8.33474 9.95098C7.88842 9.50467 7.88842 8.78105 8.33474 8.33474Z",
      "fill": "#39454C"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }