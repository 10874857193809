var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "676",
      "height": "593",
      "viewBox": "0 0 676 593",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M12.3357 17.5561C10.7675 17.3519 9.17261 17.5766 7.95004 18.4889C7.60263 18.7342 7.29443 19.0384 7.0189 19.3917C5.79212 21.0014 5.28828 23.1223 5.09572 25.0928C4.92622 26.9488 5.13 28.8117 5.59583 30.5994C6.04211 32.3151 6.81255 34.0278 7.86159 35.541L10.4218 30.9468L16.0126 32.3162L15.2001 27.7686L17.8616 28.1667L18.1399 25.8132L24.1876 25.8441C21.7145 21.6782 17.122 18.185 12.3357 17.5561Z",
      "stroke": "#ED6400",
      "stroke-width": "0.711521",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M176.299 298.886L176.117 293.9L168.11 296.89L168.698 286.24L159.456 283.095C154.22 288.35 151.108 295.64 153.471 303.127C154.228 305.54 155.529 307.792 157.222 309.697C163.535 316.748 173.609 316.551 180.842 311.195C181.483 310.727 182.092 310.228 182.687 309.683L180.681 298.644L176.299 298.886Z",
      "stroke": "#ED6400",
      "stroke-width": "0.860021",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M133.841 571.215C133.633 571.367 133.446 571.551 133.277 571.77C132.524 572.75 132.216 574.045 132.105 575.255C132 576.382 132.122 577.525 132.409 578.624C133.281 581.965 136.192 585.255 139.933 584.81C140.764 584.711 141.567 584.426 142.282 583.993C144.939 582.375 145.469 579.094 144.164 576.414C142.776 573.568 139.72 571.068 136.532 570.65C135.565 570.522 134.594 570.659 133.841 571.215Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M661.841 298.214C661.633 298.366 661.446 298.55 661.277 298.769C660.524 299.749 660.216 301.044 660.105 302.254C660 303.381 660.122 304.524 660.409 305.623C661.281 308.964 664.192 312.254 667.933 311.809C668.764 311.71 669.567 311.425 670.282 310.992C672.939 309.374 673.469 306.093 672.164 303.413C670.776 300.567 667.72 298.067 664.532 297.649C663.565 297.521 662.594 297.658 661.841 298.214Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M613.954 567.895C613.085 565.849 611.677 564.077 609.701 563.245C609.156 562.999 608.568 562.842 607.944 562.759C605.12 562.419 602.191 563.319 599.666 564.489C597.297 565.605 595.185 567.178 593.35 569.016C591.589 570.778 590.062 572.931 588.976 575.28L596.365 575.111L598.687 582.86L603.619 578.637L605.032 582.146L608.082 580.807L612.35 588.157C615.637 582.194 616.6 574.142 613.954 567.895Z",
      "stroke": "#ED6400",
      "stroke-miterlimit": "10"
    }
  }), _c('circle', {
    attrs: {
      "cx": "439.5",
      "cy": "6.5",
      "r": "6.5",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M336.729 567.283C336.398 567.018 336.026 566.802 335.607 566.628C333.731 565.858 331.548 565.927 329.585 566.272C327.754 566.591 325.997 567.276 324.381 568.202C319.464 571.021 315.507 577.045 317.821 582.779C318.335 584.051 319.132 585.201 320.125 586.146C323.83 589.657 329.254 589.086 332.936 585.867C336.846 582.444 339.491 576.529 338.781 571.302C338.568 569.715 337.933 568.236 336.729 567.283Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M432.729 260.283C432.398 260.018 432.026 259.802 431.607 259.628C429.731 258.858 427.548 258.927 425.585 259.272C423.754 259.591 421.997 260.276 420.381 261.202C415.464 264.021 411.507 270.045 413.821 275.779C414.335 277.051 415.132 278.201 416.125 279.146C419.83 282.657 425.254 282.086 428.936 278.867C432.846 275.444 435.491 269.529 434.781 264.302C434.568 262.715 433.933 261.236 432.729 260.283Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M92.7855 283.553C92.575 283.404 92.3409 283.285 92.0802 283.194C90.9116 282.792 89.586 282.91 88.4036 283.189C87.3009 283.447 86.2558 283.925 85.305 284.545C82.4124 286.432 80.2162 290.236 81.8246 293.643C82.1818 294.399 82.7068 295.071 83.3442 295.611C85.7212 297.618 89.0012 297.081 91.1288 294.993C93.388 292.774 94.7896 289.082 94.1747 285.927C93.9896 284.969 93.5514 284.091 92.7855 283.553Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }