import { render, staticRenderFns } from "./HomeTradeIcon.vue?vue&type=template&id=09fb4324&"
var script = {}


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports