<template>
  <div class="account-types ">
    <div class="bg-primary text-center text-md-right">
      <img src="../svg/illu.svg" class="ill"/>
    </div>
    <div class="account-types__detail">
      <p class="account-types__detail--header roboto">
        {{ $t("home.account-types.title") }}
      </p>
      <p class="account-types__detail--body roboto">
        {{ $t("home.account-types.content") }}
      </p>
      <p class="account-types__detail--option-header">
        {{ $t("home.account-types.subtitle") }}:
      </p>

      <div
        v-for="ben in $t('home.account-types.benefits')"
        class="account-types__detail--option-body"
      >
        <CheckRoundedIcon style="width: 25px; height: 25px" />
        <p class="account-types__detail--option-title">{{ ben }}</p>
      </div>

      <b-button
        variant="primary"
        @click="$router.push('/accounts-and-fees')"
        class="account-types__detail--button"
      >
        <p class="account-types__detail--button-detail">
          {{ $t("home.account-types.learn-more") }}
        </p>
      </b-button>
    </div>
  </div>
</template>

<script>
import AccountTypes from "../svg/account/AccountTypes";
import AccountTypesTablet from "../svg/account/AccountTypesTablet";
import AccountTypesMobile from "../svg/account/AccountTypesMobile";
import CheckRoundedIcon from "@/components/icons/CheckRoundedIcon.vue";

export default {
  components: {
    AccountTypes,
    CheckRoundedIcon,
    AccountTypesTablet,
    AccountTypesMobile,
  },
};
</script>

<style lang="scss" scoped>
.account-types {
  // overflow: hidden;
  margin-top: 160px;
  margin-bottom: 45px;
  // margin-left: auto;
  // margin-right: auto;
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 768px) and (max-width: 1199px) {
    margin-bottom: 20px;
  }
  @media (max-width: 767px) {
    margin-top: 130px;
    margin-bottom: 0px;
  }
  @media (min-width: 1200px) {
    width: 100vw;
  }
  .account-types__detail {
    background: #f8f8f8;
    padding: 64px 0px 64px 48px;
    @media (min-width: 768px) {
      width: 50vw;
    }
    @media (max-width: 767px) {
      padding-left: 34px;
    }
    .account-types__detail--header {
      height: 48px;
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      letter-spacing: -0.01em;
      color: #39454c;
      margin-bottom: 32px;
      @media (max-width: 767px) {
        font-size: 32px;
        margin-bottom: 21px;
      }
    }
    .account-types__detail--body {
      max-width: 468px;
      height: 87px;
      font-weight: 400;
      font-size: 24px;
      line-height: 120%;
      color: #616a70;
      margin-bottom: 40px;
      @media (max-width: 767px) {
        font-size: 18px;

        padding-right: 32px;
      }
      @media (max-width: 1199px) and (min-width: 768px) {
        max-width: 398px;
      }
    }
    .account-types__detail--option-header {
      height: 27px;
      //font-family: "Open Sans";
      font-weight: 700;
      font-size: 18px;
      line-height: 150%;
      color: #39454c;
      @media (max-width: 767px) {
        font-size: 16px;
      }
    }
    .account-types__detail--option-body {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .account-types__detail--option-title {
        margin-left: 8px;
        height: 18px;
        //font-family: "Open Sans";
        font-weight: 600;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0.02em;
        color: #39454c;
        margin-bottom: 0px;
      }
    }
    .account-types__detail--button {
      justify-content: center;
      align-items: center;
      width: 185px;
      height: 48px;
      background: #07b53b;
      border-radius: 95.8163px;
      margin-top: 10px;
      @media (max-width: 767px) {
        margin-top: 37px;

        margin-left: 61px;
      }

      .account-types__detail--button-detail {
        height: 17px;
        //font-family: "Open Sans";
        font-weight: 700;
        font-size: 17.034px;
        line-height: 100%;
        text-align: center;
        letter-spacing: 0.02em;
        color: #fcfcfd;
        margin-bottom: 0px;
      }
    }
  }
}
.account-types__detail--button:hover {
  background-color: #06902f !important;
}
.ill{
    @media (min-width: 1200px) {
        margin-top: 60px;
        margin-right: 140px;
        width: 440px;
        height: 496px;
    }
    @media (max-width: 1199px) and (min-width: 768px){
        margin-top: 93px;
        margin-right: 76px;
        width: 380px;
        height: 430px;
    }
    @media (max-width: 767px) {
        margin: 58px auto;
        width: 300px;
        height: 337px;
    }
}
</style>
