<template>
  <div class="hero-custom">
    <div class="hero-custom__content mx-auto">
      <h1 class="hero-custom__title">
        {{ $t("home.hero.header") }}
        <span class="text-primary">{{ $t("home.hero.header-motion") }}</span>
      </h1>
      <h2 class="hero-custom__text hero-subtitle text-grey-primary-main-500">
        {{ $t("home.hero.sub-header") }}
      </h2>

      <div class="hero-buttons d-none d-md-flex">
        <b-button
          v-if="portal_url"
          variant="primary"
          class="round-btn-16-24"
          @click="goToSignUp()"
        >
          <p
            class="button-medium text-white-button"
            :class="$i18n.locale == 'en' ? 'width160' : ''"
          >
            {{ $t("home.hero.signup") }}
          </p>
        </b-button>

        <b-button
          v-if="portal_url"
          variant="outline-primary"
          class="round-btn-16-24-outline align-middle"
          @click="goToSignUp()"
          ><p class="button-medium">
            {{ $t("home.hero.try demo") }}
          </p>
        </b-button>
      </div>
    </div>

    <div class="d-none d-xl-block">
      <HomeHero />
    </div>
    <div class="d-none d-md-block d-xl-none">
      <HomeTabletHero />
    </div>

    <div class="d-block d-md-none">
      <HomeMobileHero />
    </div>

    <div
      class="d-block d-md-none mx-auto w-100"
      style="gap: 14px; padding: 32px; margin-bottom: 23px"
    >
      <b-button
        v-if="portal_url"
        variant="primary"
        class="d-block mx-auto round-btn-16-24 w-100"
        @click="goToSignUp()"
        ><p class="button-medium text-white-button">
          {{ $t("home.hero.signup") }}
        </p></b-button
      >

      <b-button v-if="portal_url"
        @click="goToSignUp()"
        variant="outline-primary"
        class="w-100 round-btn-16-24-outline d-block mx-auto"
        style="margin-top: 14px"
        ><p class="button-medium text-nowrap">
          {{ $t("home.hero.try demo") }}
        </p></b-button
      >
    </div>
  </div>
</template>
<script>
import HomeHero from "../svg/hero/HomeHero.vue";
import HomeMobileHero from "../svg/hero/HomeMobileHero.vue";
import HomeTabletHero from "../svg/hero/HomeTabletHero.vue";
import { mapActions, mapGetters } from "vuex";


export default {
  components: { HomeHero, HomeMobileHero, HomeTabletHero },
  computed: {
    ...mapGetters({
      portal_url: "portal_url",
    }),
  },
  methods: {
    goToSignUp() {
      if (this.portal_url)
        window.location.href = `${this.portal_url}/register`;
    },
  },
};
</script>

<style lang="scss" scoped>
.hero-content {
  @media (max-width: 767px) {
    margin-top: 51px;
    max-width: 305px;
  }
  @media (min-width: 1200px) {
    h2 {
      margin-top: 8px;
      margin-bottom: 16px !important;
    }
  }
}
.desk-hero {
  margin-left: -310px;
}
.tab-hero {
  margin-left: -480px;
}
.hero-custom__text {
  width: 414px;
  @media (min-width: 992px) and (max-width: 1199px) {
    width: 304px;
  }
  @media (max-width: 991px) {
    width: 280px;
  }
}

.width160 {
  width: 160px;
}
</style>
