var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M15.8346 5.00065H4.16797C3.70773 5.00065 3.33464 5.37375 3.33464 5.83398V15.834C3.33464 16.2942 3.70773 16.6673 4.16797 16.6673H15.8346C16.2949 16.6673 16.668 16.2942 16.668 15.834V5.83398C16.668 5.37375 16.2949 5.00065 15.8346 5.00065ZM4.16797 3.33398C2.78726 3.33398 1.66797 4.45327 1.66797 5.83398V15.834C1.66797 17.2147 2.78726 18.334 4.16797 18.334H15.8346C17.2153 18.334 18.3346 17.2147 18.3346 15.834V5.83398C18.3346 4.45327 17.2153 3.33398 15.8346 3.33398H4.16797Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.33333 10C7.8731 10 7.5 10.3731 7.5 10.8333C7.5 11.2936 7.8731 11.6667 8.33333 11.6667H14.1667C14.6269 11.6667 15 11.2936 15 10.8333C15 10.3731 14.6269 10 14.1667 10H8.33333ZM5.83333 13.3333C5.3731 13.3333 5 13.7064 5 14.1667C5 14.6269 5.3731 15 5.83333 15H10.8333C11.2936 15 11.6667 14.6269 11.6667 14.1667C11.6667 13.7064 11.2936 13.3333 10.8333 13.3333H5.83333Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.83333 1.66602C5.3731 1.66602 5 2.03911 5 2.49935V5.83268C5 6.29292 5.3731 6.66602 5.83333 6.66602C6.29357 6.66602 6.66667 6.29292 6.66667 5.83268V2.49935C6.66667 2.03911 6.29357 1.66602 5.83333 1.66602ZM14.1667 1.66602C13.7064 1.66602 13.3333 2.03911 13.3333 2.49935V5.83268C13.3333 6.29292 13.7064 6.66602 14.1667 6.66602C14.6269 6.66602 15 6.29292 15 5.83268V2.49935C15 2.03911 14.6269 1.66602 14.1667 1.66602Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }