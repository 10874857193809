var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.5013 16.668C9.80249 16.668 11.668 14.8025 11.668 12.5013C11.668 10.2001 9.80249 8.33464 7.5013 8.33464C5.20012 8.33464 3.33464 10.2001 3.33464 12.5013C3.33464 14.8025 5.20012 16.668 7.5013 16.668ZM7.5013 18.3346C10.723 18.3346 13.3346 15.723 13.3346 12.5013C13.3346 9.27964 10.723 6.66797 7.5013 6.66797C4.27964 6.66797 1.66797 9.27964 1.66797 12.5013C1.66797 15.723 4.27964 18.3346 7.5013 18.3346Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.5016 3.33464C11.2676 3.33464 10.1598 3.86997 9.39569 4.72373C9.08875 5.06667 8.56192 5.09585 8.21899 4.78891C7.87605 4.48198 7.84686 3.95515 8.1538 3.61221C9.22073 2.42014 10.7739 1.66797 12.5016 1.66797C15.7233 1.66797 18.3349 4.27964 18.3349 7.5013C18.3349 9.22902 17.5828 10.7822 16.3907 11.8491C16.0477 12.156 15.5209 12.1268 15.214 11.7839C14.907 11.441 14.9362 10.9141 15.2792 10.6072C16.1329 9.84306 16.6683 8.73525 16.6683 7.5013C16.6683 5.20012 14.8028 3.33464 12.5016 3.33464Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }