var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "yay",
    staticClass: "section market"
  }, [_c('h2', [_vm._v(" " + _vm._s(_vm.$t('components.market trend.market trends')) + " ")]), _c('div', {
    staticClass: "market-buttons mb-5"
  }, _vm._l(_vm.marketTrendsTypes.filter(function (market) {
    return _vm.nowUrl == '' || !market.onlyFrontPage;
  }), function (market) {
    return _c('b-button', {
      key: market.name,
      class: ['market-button', _vm.marketType == market.name ? 'marketTrendsButtonActive' : 'marketTrendsButton', {
        'light-theme-title': _vm.marketType !== market.name
      }],
      attrs: {
        "variant": _vm.marketType == market.name ? 'success' : 'outline-success'
      },
      on: {
        "click": function ($event) {
          return _vm.goToMarket(market.name);
        }
      }
    }, [_c('p', {
      staticClass: "body-paragraph-bold"
    }, [_vm._v(" " + _vm._s(_vm.$t(market.i18nText)) + " ")])]);
  }), 1), _c('div', {
    staticClass: "d-md-none",
    staticStyle: {
      "margin-bottom": "32px"
    }
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.marketType,
      expression: "marketType"
    }],
    staticClass: "caption-bold",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.marketType = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }
    }
  }, _vm._l(_vm.marketTrendsTypes.filter(function (market) {
    return _vm.nowUrl == '' || !market.onlyFrontPage;
  }), function (market) {
    return _c('option', {
      key: market.name,
      domProps: {
        "value": market.name
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(market.i18nText)) + " ")]);
  }), 0)]), _vm.loadTable && _vm.currentMarket ? _c('div', [_c('MarketTable', {
    key: _vm.currentMarket.name,
    attrs: {
      "showFull": _vm.showFull,
      "nowUrl": _vm.nowUrl,
      "symbols": _vm.currentSymbols,
      "exampleSymbols": _vm.currentMarket.exampleSymbols
    }
  })], 1) : _c('div', {
    staticClass: "text-center text-success my-2"
  }, [_c('b-spinner', {
    staticClass: "align-middle"
  })], 1), _c('div', {
    staticClass: "disclosure d-flex align-items-center justify-content-between",
    staticStyle: {
      "color": "#39454c",
      "text-decoration": "none",
      "height": "64px !important"
    }
  }, [_c('div', {
    directives: [{
      name: "b-modal",
      rawName: "v-b-modal.riskDisclosure",
      modifiers: {
        "riskDisclosure": true
      }
    }],
    staticClass: "d-flex align-items-center"
  }, [_c('RiskDisclosureIcon', {
    staticClass: "d-none d-md-block",
    staticStyle: {
      "margin-right": "4px"
    }
  }), _c('RiskDisclosureIconMobile', {
    staticClass: "d-md-none",
    staticStyle: {
      "margin-right": "4px"
    }
  }), _c('h4', {
    staticClass: "light-theme-title risk"
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.risk disclosure.title')) + " ")])], 1), _vm.nowUrl !== '' && _vm.marketType !== 'Newest trends' ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-center expand",
    on: {
      "click": function ($event) {
        _vm.showFull = !_vm.showFull;
      }
    }
  }, [!_vm.showFull ? _c('h4', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.expand')) + " ")]) : _c('h4', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.hide')) + " ")]), _c('RightChevron', {
    staticStyle: {
      "margin-left": "9.5px"
    }
  })], 1) : _vm._e()]), !_vm.nowUrl ? _c('div', {
    staticClass: "d-flex justify-content-center vm-but w-100",
    staticStyle: {
      "margin-top": "64px"
    }
  }, [_vm.marketType !== 'Newest trends' ? _c('router-link', {
    staticClass: "link w-100 d-flex justify-content-center",
    staticStyle: {
      "margin": "auto"
    },
    attrs: {
      "to": {
        name: 'Markets',
        query: {
          marketType: _vm.marketType
        },
        params: {
          showFull: true
        }
      }
    }
  }, [_c('b-button', {
    staticClass: "round-btn-16-24-outline vm-but vm",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('p', {
    staticClass: "button-small-to-medium text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.view more')) + " ")])])], 1) : _vm._e()], 1) : _vm._e(), _vm.nowUrl == 'markets' && _vm.marketType !== 'Newest trends' ? _c('div', [_c('div', {
    staticClass: "d-md-flex justify-content-center learnMore"
  }, [_c('a', {
    staticClass: "link",
    staticStyle: {
      "color": "inherit",
      "text-decoration": "none"
    },
    attrs: {
      "href": _vm.marketType == 'Crypto' ? ("https://nozax.kb.help/" + (_vm.marketType.toLowerCase()) + "-trading-with-nozax/") : ("https://nozax.kb.help/what-is-" + (_vm.marketType == 'Shares' ? 'stocks' : _vm.marketType.toLowerCase()) + "-trading/"),
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-12-16-outline",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('p', {
    staticClass: "button-small"
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.learn more about') + ' ' + _vm.$t(("components.market trend." + (_vm.marketType.toLowerCase())))) + " ")])])], 1)])]) : _vm._e(), _vm.nowUrl == 'markets' && _vm.marketType === 'Newest trends' ? _c('div', {
    staticStyle: {
      "height": "144px"
    }
  }) : _vm._e(), _c('b-modal', {
    attrs: {
      "id": "riskDisclosure",
      "size": "lg",
      "hide-header": true,
      "hide-footer": true,
      "no-close-on-backdrop": true,
      "no-close-on-esc": true
    }
  }, [_c('span', {
    staticClass: "position-absolute close-icon d-flex justify-content-center align-items-center",
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide('riskDisclosure');
      }
    }
  }, [_c('CloseIcon')], 1), _c('div', [_c('h1', {
    attrs: {
      "id": "title"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('components.market trend.risk disclosure.title')) + " ")]), _c('p', {
    attrs: {
      "id": "part"
    }
  }, [_c('span', {
    staticStyle: {
      "font-weight": "700 !important"
    }
  }, [_vm._v(_vm._s(_vm.$t('components.market trend.risk disclosure.p1')))]), _vm._v(" " + _vm._s(_vm.$t('components.market trend.risk disclosure.p2')) + " ")]), _c('br'), _c('p', {
    attrs: {
      "id": "part1"
    }
  }, [_vm._v(_vm._s(_vm.$t('components.market trend.risk disclosure.p3')))])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }