var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "377",
      "height": "447",
      "viewBox": "0 0 377 447",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "377",
      "height": "447",
      "fill": "#07B53B"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "173.779",
      "cy": "204.432",
      "r": "121.15",
      "fill": "#07B53B"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "260.221",
      "cy": "309.929",
      "r": "67.3814",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "220.15",
      "cy": "126.159",
      "r": "15.6543",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "192.874",
      "cy": "179.874",
      "r": "4.70994",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "127.508",
      "cy": "267.048",
      "r": "11.5705",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "112.749",
      "cy": "221.66",
      "r": "4.77317",
      "stroke": "white",
      "stroke-width": "1.77186"
    }
  }), _c('circle', {
    attrs: {
      "cx": "298.561",
      "cy": "281.554",
      "r": "4.47786",
      "stroke": "white",
      "stroke-width": "2.36248"
    }
  }), _c('circle', {
    attrs: {
      "cx": "168.518",
      "cy": "69.6591",
      "r": "4.77317",
      "stroke": "white",
      "stroke-width": "1.77186"
    }
  }), _c('path', {
    attrs: {
      "d": "M83.4185 248.98H37C37 243.061 40.9452 238.027 46.4385 236.185C47.8118 230.093 53.445 225.529 60.1868 225.529C66.9186 225.529 72.5467 230.083 73.925 236.166C79.4483 237.993 83.4185 243.042 83.4185 248.98Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M87.5882 241.446H61.4414C61.4414 238.146 63.6637 235.339 66.758 234.312C67.5315 230.915 70.7046 228.371 74.5021 228.371C78.2941 228.371 81.4643 230.91 82.2407 234.301C85.3519 235.32 87.5882 238.135 87.5882 241.446Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M68.9883 90.084C82.24 107.684 117.44 138.908 152.226 123.006C195.708 103.129 249.75 121.143 238.569 175.806C227.388 230.469 87.6235 229.848 109.365 338.554C131.106 447.259 281.715 402.499 289.169 287.582",
      "stroke": "white",
      "stroke-width": "1.61824",
      "stroke-linecap": "round",
      "stroke-dasharray": "3.24 3.24"
    }
  }), _c('rect', {
    attrs: {
      "x": "57.3158",
      "y": "70.0296",
      "width": "70.0447",
      "height": "83.9839",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.04558"
    }
  }), _c('rect', {
    attrs: {
      "x": "76.1048",
      "y": "64.7445",
      "width": "31.8387",
      "height": "10.1116",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.04558"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "153.382",
      "cy": "169.491",
      "rx": "5.44049",
      "ry": "5.4411",
      "fill": "white"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "252.273",
      "cy": "218.089",
      "rx": "5.85899",
      "ry": "5.85964",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "96.0426",
      "cy": "194.508",
      "r": "3.91368",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "243.924",
      "cy": "348.738",
      "r": "4.78338",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "174.502",
      "cy": "277.954",
      "r": "4.78338",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M136.382 325.888H79.1914C79.1914 318.595 84.0522 312.392 90.8203 310.123C92.5123 302.616 99.4528 296.994 107.759 296.994C116.053 296.994 122.987 302.605 124.686 310.099C131.491 312.35 136.382 318.571 136.382 325.888Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M336.076 190.887H253.434C253.434 180.218 260.458 171.144 270.238 167.825C272.683 156.844 282.712 148.619 294.715 148.619C306.7 148.619 316.72 156.826 319.174 167.79C329.007 171.083 336.076 180.183 336.076 190.887Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "159.404",
      "y": "207.27",
      "width": "51.6996",
      "height": "28.6584",
      "rx": "2.75341",
      "transform": "rotate(-12.4135 159.404 207.27)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.966107"
    }
  }), _c('rect', {
    attrs: {
      "x": "167.815",
      "y": "209.233",
      "width": "51.6996",
      "height": "28.6584",
      "rx": "2.75341",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "0.966107"
    }
  }), _c('rect', {
    attrs: {
      "x": "167.531",
      "y": "220.702",
      "width": "52.6528",
      "height": "2.89832",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "204.09",
      "y": "213.413",
      "width": "13.1664",
      "height": "4.11451",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M67.7227 88.7236H103.161",
      "stroke": "#07B53B",
      "stroke-width": "3.13673",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M67.6094 97.0889H103.159",
      "stroke": "#07B53B",
      "stroke-width": "3.13673",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M67.7227 108.659H103.161",
      "stroke": "#07B53B",
      "stroke-width": "3.13673",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M67.6094 117.024H88.939",
      "stroke": "#07B53B",
      "stroke-width": "3.13673",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "254.627",
      "cy": "302.977",
      "r": "25.379",
      "stroke": "#07B53B",
      "stroke-width": "2.04186"
    }
  }), _c('path', {
    attrs: {
      "d": "M271.777 322.667L283.675 334.565",
      "stroke": "#07B53B",
      "stroke-width": "2.04186",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M280.988 331.879L292.886 343.777",
      "stroke": "#07B53B",
      "stroke-width": "6.8062",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M243.629 303.297L251.174 310.842L266.265 295.752",
      "stroke": "#07B53B",
      "stroke-width": "2.04186",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }