var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "section trading position-relative"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 col-md-10 p-0"
  }, [_c('h2', {
    staticClass: "trading-title text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.title")) + " ")]), _c('h4', {
    staticClass: "text-center subtitle-bodyLarge text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.sub-title")) + " ")]), _c('div', {
    staticClass: "position-absolute d-none d-md-block",
    staticStyle: {
      "top": "18%"
    }
  }, [_c('HomeTradingIcon')], 1), _c('div', {
    staticClass: "d-md-none position-absolute",
    staticStyle: {
      "top": "29%",
      "left": "30%",
      "z-index": "5"
    }
  }, [_c('HomeMobileTradingIcon')], 1), _c('div', {
    staticClass: "trading-items"
  }, [_c('div', {
    staticClass: "trading-item position-relative paddingSwitchByLang",
    attrs: {
      "id": "re"
    }
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.register")) + " ")]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "14px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 re"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.register-text")) + " ")]), _vm._m(0)])]), _c('div', {
    staticClass: "trading-item position-relative paddingSwitchByLang",
    attrs: {
      "id": "expert"
    }
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.learn")) + " ")]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "14px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 le"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.learn-text")) + " ")])]), _vm._m(1)]), _c('div', {
    staticClass: "trading-item position-relative paddingSwitchByLang",
    attrs: {
      "id": "set"
    }
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.setup")) + " ")]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "14px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 set"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.setup-text")) + " ")])]), _vm._m(2)]), _c('div', {
    staticClass: "trading-item position-relative paddingSwitchByLang",
    attrs: {
      "id": "go"
    }
  }, [_c('h3', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.trade")) + " ")]), _c('div', {
    staticClass: "d-flex",
    staticStyle: {
      "gap": "14px"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "25px",
      "height": "25px"
    }
  }, [_c('CheckRoundedIcon')], 1), _c('p', {
    staticClass: "trading-text body-paragraph-1-to-mobile-body-regular text-grey-primary-main-500 tr"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.trade-text")) + " ")])]), _vm._m(3)])]), _c('a', {
    staticClass: "link d-flex justify-content-center",
    attrs: {
      "href": "https://nozax.kb.help/how-do-i-open-an-account-with-nozax/",
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "round-btn-16-but-17-24-outline my-but",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('p', {
    staticClass: "button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.trading time.learn more")) + " ")])])], 1)])])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mark-wrap"
  }, [_c('div', {
    staticClass: "mark"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mm-wrap"
  }, [_c('div', {
    staticClass: "mm"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mark-wrap"
  }, [_c('div', {
    staticClass: "mark"
  })]);
},function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mm-wrap"
  }, [_c('div', {
    staticClass: "mm"
  })]);
}]

export { render, staticRenderFns }