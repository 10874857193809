import Vue from "vue";

export default {
  state: () => ({
    symbols: {},
    symbols_history: {},
    symbol_long_name: null,
  }),
  mutations: {
    UPDATE_SYMBOL(state, { symbols }) {
      symbols.forEach((symbol) => {
        Vue.set(state.symbols, symbol.symbol, { ...symbol, chartData: [] });
      });
      Vue.set(state, symbols, state.symbols);
    },
    UPDATE_SYMBOL_LONG_NAME(state, data) {
      state.symbol_long_name = data;
    },
    UPDATE_SYMBOL_HISTORY(state, { symbol, history }) {
      Vue.set(state.symbols_history, symbol, history);

      let chartData = history.map((bar) => {
        return {
          value: (bar.low + bar.high) / 2,
          LastTick: new Date(bar.datetime),
        };
      });
      if (state.symbols[symbol]) {
        state.symbols[symbol].chartData.push(...chartData);
      }
    },
    LIVE_UPDATE(state, { symbol }) {
      if (state.symbols[symbol.symbol]) {
        state.symbols[symbol.symbol].bid =
          symbol.bid -
          (state.symbols[symbol.symbol].point ?? 0) *
            (state.symbols[symbol.symbol].bid_spread ?? 0);
        state.symbols[symbol.symbol].ask =
          symbol.ask +
          (state.symbols[symbol.symbol].point ?? 0) *
            (state.symbols[symbol.symbol].ask_spread ?? 0);
      }
    },
  },
  actions: {
    getAllSymbolConfigs({ commit }) {
      return new Promise((resolve, reject) => {
        return Vue.prototype.$axios
          .post(`site_settings/get_public_symbol_configs`)
          .then((response) => {
            commit("UPDATE_SYMBOL", { symbols: response.data?.configs || [] });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSymbolsDescription({ commit }) {
      return new Promise((resolve, reject) => {
        return Vue.prototype.$axios
          .post(`site_settings/get_symbols_with_customized_description`)
          .then((response) => {
            commit("UPDATE_SYMBOL_LONG_NAME", response.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getSymbolConfigs({ commit }, { symbols }) {
      return new Promise((resolve, reject) => {
        return Vue.prototype.$axios
          .post(`site_settings/get_public_symbol_configs`, { symbols: symbols })
          .then((response) => {
            commit("UPDATE_SYMBOL", { symbols: response.data?.configs || [] });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    fetchSymbolHistory({ commit }, { symbol }) {
      return new Promise((resolve, reject) => {
        return Vue.prototype.$axios
          .post(`site_settings/get_symbol_minute_bars`, {
            symbol_minutes_bars: {
              symbol: symbol,
            },
          })
          .then((response) => {
            commit("UPDATE_SYMBOL_HISTORY", {
              symbol: symbol,
              history: response.data,
            });
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  getters: {
    symbols(state) {
      return Object.values(state.symbols).map((symbol) => ({
        ...symbol,
        long_name:
          state.symbol_long_name?.[symbol.server]?.children?.symbols?.settings[
            symbol.symbol
          ]?.description?.split(" - ")[0],
      }));
    },
    getSymbolHistory(state) {
      return (symbol) => {
        return state.symbols_history[symbol];
      };
    },
    symbolsHistory(state) {
      let tmp = Object.entries(state.symbols_history).map(
        ([symbol, history]) => {}
      );
      return tmp.reduce((obj, item, index) => {
        obj[item.symbol] = item.history;
        return obj;
      });
    },
  },
};
