import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// i18n
import i18n from "@/libs/i18n";

// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// custom styles
import "@/styles/sass/index.scss";

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

// vue apex chart
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);

Vue.component("apexchart", VueApexCharts);
import SsrCarousel from "vue-ssr-carousel";
// import ssrCarouselCss from "vue-ssr-carousel/index.css";
Vue.component("ssr-carousel", SsrCarousel);

// Phone Number Input
import { MazPhoneNumberInput } from "maz-ui";
Vue.component(MazPhoneNumberInput.name, MazPhoneNumberInput);

// i18n
import VueI18n from "vue-i18n";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRight,
  faEquals,
  faEye,
  faEyeSlash,
  faChevronDown,
  faTimes,
  faChevronUp,faChevronLeft, faChevronRight
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";

Vue.component("v-select", vSelect);

library.add(faArrowRight);
library.add(faEquals);
library.add(faEye);
library.add(faEyeSlash);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faTimes);
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueI18n);

import Clipboard from 'v-clipboard'

Vue.use(Clipboard)


import ActionCableVue from "actioncable-vue";
import { getWebSocketURL } from "@/utils/cable";
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: "error",
  connectionUrl: getWebSocketURL, // or function which returns a string with your JWT appended to your server URL as a query parameter
  connectImmediately: false,
});

Vue.config.productionTip = false;

import VueCarousel from "vue-carousel";

Vue.use(VueCarousel);

import VueGtag from "vue-gtag";
Vue.use(
  VueGtag,
  {
    includes: [
      { id: "AW-404919006" },
      { id: "G-JS7P42T4KM" },
      { id: "UA-230253241-1" },
    ],
  },
  router
);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  methods: {
    disconnectActionCable() {
      this.$cable.connection.disconnect();
    },
  },
}).$mount("#app");
