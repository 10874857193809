var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M6.8125 8C6.08763 8 5.5 8.59695 5.5 9.33333C5.5 10.0697 6.08763 10.6667 6.8125 10.6667H25.1875C25.9124 10.6667 26.5 10.0697 26.5 9.33333C26.5 8.59695 25.9124 8 25.1875 8H6.8125Z",
      "fill": "#39454C"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.8125 14.667C6.08763 14.667 5.5 15.2639 5.5 16.0003C5.5 16.7367 6.08763 17.3337 6.8125 17.3337H25.1875C25.9124 17.3337 26.5 16.7367 26.5 16.0003C26.5 15.2639 25.9124 14.667 25.1875 14.667H6.8125Z",
      "fill": "#39454C"
    }
  }), _c('path', {
    attrs: {
      "d": "M6.8125 21.333C6.08763 21.333 5.5 21.93 5.5 22.6663C5.5 23.4027 6.08763 23.9997 6.8125 23.9997H25.1875C25.9124 23.9997 26.5 23.4027 26.5 22.6663C26.5 21.93 25.9124 21.333 25.1875 21.333H6.8125Z",
      "fill": "#39454C"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }