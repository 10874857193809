export const exampleSymbols = JSON.parse(process.env.VUE_APP_FRONTPAGE_SYMBOLS);

const symbol_types = [
  {
    type: "newest_trends",
    name: "Newest trends",
    i18nText: "components.market trend.newest trends",
    onlyFrontPage: true,
    component: "NewestTrends",
    exampleSymbols: exampleSymbols.newest_trends,
    checkSymbol: (symbol) => {
      return exampleSymbols.newest_trends.includes(symbol.symbol);
    },
  },
  {
    type: "forex",
    name: "Forex",
    i18nText: "components.market trend.forex",
    component: "Forex",
    exampleSymbols: exampleSymbols.forex,
    checkSymbol: (symbol) => {
      return symbol.site_setting?.frontpage_market == "Forex";
    },
  },
  {
    type: "shares",
    name: "Shares",
    i18nText: "components.market trend.shares",
    component: "Shares",
    exampleSymbols: exampleSymbols.shares,
    checkSymbol: (symbol) => {
      return symbol.site_setting?.frontpage_market == "Shares";
    },
  },
  {
    type: "indices",
    name: "Indices",
    i18nText: "components.market trend.indices",
    component: "Indices",
    exampleSymbols: exampleSymbols.indices,
    checkSymbol: (symbol) => {
      return symbol.site_setting?.frontpage_market == "Indices";
    },
  },
  {
    type: "crypto",
    name: "Crypto",
    i18nText: "components.market trend.crypto",
    component: "Crypto",
    exampleSymbols: exampleSymbols.crypto,
    checkSymbol: (symbol) => {
      return symbol.site_setting?.frontpage_market == "Crypto";
    },
  },
  {
    type: "commodities",
    name: "Commodities",
    i18nText: "components.market trend.commodities",
    component: "Commodities",
    exampleSymbols: exampleSymbols.commodities,
    checkSymbol: (symbol) => {
      return symbol.site_setting?.frontpage_market == "Commodities";
    },
  },
];

export const getSymbolType = (symbol) => {
  return symbol_types.findLast((symbol_type) =>
    symbol_type.checkSymbol(symbol)
  );
};

export const getIconForSymbol = (symbol_config) => {
  if (symbol_config?.icons_urls) {
    return symbol_config?.icons_urls.slice(-1)[0].url;
  }
  var icon = null;
  try {
    icon = require(`@/components/market-trends/iconsForTables/svgHolder.svg`);
  } catch (err) {
    console.error(err);
  }
  return icon;
};

export default symbol_types;
