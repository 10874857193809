var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M7.5013 5.24321C6.94554 5.40891 6.41458 5.65745 5.92715 5.98314C4.96786 6.62412 4.22019 7.53516 3.77867 8.60106C3.33716 9.66697 3.22164 10.8399 3.44672 11.9714C3.67181 13.103 4.22738 14.1424 5.04318 14.9582C5.85899 15.774 6.89839 16.3296 8.02995 16.5546C9.1615 16.7797 10.3344 16.6642 11.4003 16.2227C12.4662 15.7812 13.3772 15.0335 14.0182 14.0742C14.3439 13.5868 14.5924 13.0558 14.7581 12.5001H9.58464C8.43405 12.5001 7.50131 11.5673 7.50131 10.4167L7.5013 5.24321ZM8.33634 3.37963C8.79374 3.3286 9.16797 3.70648 9.16797 4.16672V10.4167C9.16797 10.6468 9.35452 10.8334 9.58464 10.8334H15.8346C16.2949 10.8334 16.6728 11.2076 16.6217 11.665C16.4891 12.8542 16.0732 13.9986 15.404 15.0002C14.5799 16.2335 13.4085 17.1948 12.0381 17.7625C10.6677 18.3301 9.15965 18.4787 7.70479 18.1893C6.24994 17.8999 4.91357 17.1856 3.86467 16.1367C2.81578 15.0878 2.10147 13.7514 1.81208 12.2966C1.52269 10.8417 1.67122 9.3337 2.23888 7.96326C2.80653 6.59281 3.76783 5.42147 5.0012 4.59736C6.00276 3.92814 7.1472 3.5123 8.33634 3.37963Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M16.4222 7.5007C16.3651 7.30918 16.2981 7.12037 16.2213 6.93505C15.9282 6.22731 15.4985 5.58425 14.9568 5.04258C14.4151 4.5009 13.7721 4.07122 13.0644 3.77807C12.879 3.7013 12.6902 3.63429 12.4987 3.57719V7.5007H16.4222ZM18.2858 8.33574C18.3368 8.79314 17.9589 9.16737 17.4987 9.16737H11.2487C11.0186 9.16737 10.832 8.98082 10.832 8.7507V2.5007C10.832 2.04046 11.2063 1.66258 11.6637 1.71361C12.3631 1.79165 13.0494 1.9679 13.7022 2.23827C14.6121 2.61518 15.4389 3.16762 16.1353 3.86406C16.8318 4.5605 17.3842 5.3873 17.7611 6.29724C18.0315 6.94998 18.2077 7.63633 18.2858 8.33574Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }