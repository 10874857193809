var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M14.7866 7.25846H5.21628C4.25491 7.25846 3.49289 8.0696 3.55286 9.02909L3.96952 15.6958C4.02442 16.5742 4.75284 17.2585 5.63295 17.2585H14.3699C15.25 17.2585 15.9785 16.5742 16.0334 15.6958L16.45 9.02909C16.51 8.0696 15.748 7.25846 14.7866 7.25846ZM5.21628 5.5918C3.29354 5.5918 1.7695 7.21406 1.88944 9.13306L2.3061 15.7997C2.4159 17.5565 3.87273 18.9251 5.63295 18.9251H14.3699C16.1301 18.9251 17.587 17.5565 17.6968 15.7997L18.1134 9.13306C18.2334 7.21406 16.7093 5.5918 14.7866 5.5918H5.21628Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.83203 6.42546C5.83203 4.12427 7.69751 2.25879 9.9987 2.25879C12.2999 2.25879 14.1654 4.12427 14.1654 6.42546V8.09212C14.1654 8.55236 13.7923 8.92546 13.332 8.92546C12.8718 8.92546 12.4987 8.55236 12.4987 8.09212V6.42546C12.4987 5.04474 11.3794 3.92546 9.9987 3.92546C8.61799 3.92546 7.4987 5.04474 7.4987 6.42546V8.09212C7.4987 8.55236 7.1256 8.92546 6.66536 8.92546C6.20513 8.92546 5.83203 8.55236 5.83203 8.09212V6.42546Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }