<template>
  <div class="started section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 p-0">
          <h2 class="text-center light-theme-title">
            {{ $t("home.get started.title") }}
          </h2>
          <h4 class="text-center subtitle-bodyLarge text-grey-primary-main-500">
            {{ $t("home.get started.sub-title") }}
            <span class="text-primary">{{
              $t("home.get started.sub-title-green")
            }}</span>
          </h4>
          <div
            class="started-items d-flex flex-column flex-md-row justify-content-center align-items-start"
          >
            <div
              class="started-item d-flex flex-column justify-content-start align-items-center"
            >
              <div class="started-icon">
                <HomeRegisterIcon />
              </div>
              <div class="started-content text-center">
                <h6
                  class="body-paragraph-bold-to-custom-to-h3 light-theme-title"
                >
                  {{ $t("home.get started.register") }}
                </h6>
                <p
                  id="re"
                  class="body-small-text-to-mobile-body-regular text-grey-primary-main-500"
                >
                  {{ $t("home.get started.register-text") }}
                </p>
              </div>
            </div>

            <div
              class="started-item d-flex flex-column justify-content-start align-items-center"
              style="gap: 24px"
            >
              <div class="started-icon">
                <HomeVerifyIcon />
              </div>
              <div class="started-content text-center">
                <h6
                  class="body-paragraph-bold-to-custom-to-h3 light-theme-title"
                >
                  {{ $t("home.get started.verify") }}
                </h6>
                <p
                  id="verify"
                  class="body-small-text-to-mobile-body-regular text-grey-primary-main-500"
                >
                  {{ $t("home.get started.verify-text") }}
                </p>
              </div>
            </div>

            <div
              class="started-item d-flex flex-column justify-content-start align-items-center"
              style="gap: 24px"
            >
              <div class="started-icon">
                <HomeFundIcon />
              </div>
              <div class="started-content text-center">
                <h6
                  class="body-paragraph-bold-to-custom-to-h3 light-theme-title"
                >
                  {{ $t("home.get started.fund") }}
                </h6>
                <p
                  id="fund"
                  class="body-small-text-to-mobile-body-regular text-grey-primary-main-500"
                >
                  {{ $t("home.get started.fund-text") }}
                </p>
              </div>
            </div>

            <div
              class="started-item d-flex flex-column justify-content-start align-items-center"
              style="gap: 24px"
            >
              <div class="started-icon">
                <HomeTradeIcon />
              </div>
              <div class="started-content text-center">
                <h6
                  class="body-paragraph-bold-to-custom-to-h3 light-theme-title"
                >
                  {{ $t("home.get started.trade") }}
                </h6>
                <p
                  id="tra"
                  class="body-small-text-to-mobile-body-regular text-grey-primary-main-500"
                >
                  {{ $t("home.get started.trade-text") }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HomeFundIcon from "../svg/get-started/HomeFundIcon.vue";
import HomeRegisterIcon from "../svg/get-started/HomeRegisterIcon.vue";
import HomeVerifyIcon from "../svg/get-started/HomeVerifyIcon.vue";
import HomeTradeIcon from "../svg/get-started/HomeTradeIcon.vue";
export default {
  components: {
    HomeFundIcon,
    HomeRegisterIcon,
    HomeTradeIcon,
    HomeVerifyIcon,
  },
};
</script>
<style lang="scss" scoped>
.body-small-text-to-mobile-body-regular {
  @media (max-width: 1199px) and (min-width: 768px) {
    font-size: 12.773px !important;
    line-height: 120%;
    /* or 15px */

    text-align: center;
    letter-spacing: -0.01em;
  }
  @media (max-width: 767px) {
    font-size: 16px !important;
  }
}
h4 {
  @media screen and (max-width: 767px) {
    margin: 18px 52px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 24px;
  }
}
h6 {
  @media screen and (max-width: 767px) {
    margin-bottom: 8px !important;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 24px;
  }
}
h2 {
  @media screen and (max-width: 767px) {
    margin: 0 49px;
  }
}
p {
  @media screen and (max-width: 767px) {
    margin-bottom: 48px !important;
    max-width: 300px;
  }
  @media screen and (max-width: 1199px) and (min-width: 768px) {
    margin-top: 13px;
  }
  @media screen and (min-width: 1200px) {
    margin-top: 8px;
    line-height: 19.2px !important;
  }
}
.started {
  @media (min-width: 768px) {
    margin-top: 164px !important;
  }
  @media (max-width: 768px) {
    margin-top: 150px !important;
  }
  .started-items {
    @media screen and (max-width: 767px) {
      margin-top: 39px;
    }
    @media screen and (max-width: 1199px) and (min-width: 768px) {
      margin-top: 53px;
      gap: 20px;
    }
    @media screen and (min-width: 1200px) {
      margin-top: 43px;
      gap: 20px;
    }
    .started-item {
      @media (max-width: 767px) {
        width: 100%;
      }
      @media screen and (min-width: 768px) and (max-width: 1199px) {
        gap: 24px;
        width: 256px;
        #re {
          max-width: 172px !important;
          margin: 8px auto 16px auto !important;
        }
        #verify {
          max-width: 200px !important;
          margin: 8px auto 16px auto !important;
        }

        #fund {
          max-width: 185px !important;
          margin: 8px auto 16px auto !important;
        }
        #tra {
          max-width: 155px !important;
          margin: 8px auto 16px auto !important;
        }
      }
      @media (max-width: 767px) {
        gap: 16px;
      }
      @media (min-width: 1200px) {
        gap: 24px;
        width: 256px;
        #re {
          max-width: 195px !important;
          margin: 8px auto 16px auto !important;
        }
        #verify {
          max-width: 256px !important;
          margin: 8px auto 16px auto !important;
        }

        #fund {
          max-width: 225px !important;
          margin: 8px auto 16px auto !important;
        }
        #tra {
          max-width: 187px !important;
          margin: 8px auto 16px auto !important;
        }
      }
      .started-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        background: #ffffff;
        filter: drop-shadow(0px 8px 16px rgba(218, 225, 233, 0.56));
        @media (min-width: 767px) {
          width: 88px;
          height: 88px;
        }
      }
    }
  }
}
</style>
