var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-row align-items-center"
  }, [_c('span', {
    staticClass: "entity-text"
  }, [_vm._v("Entity:")]), _c('b-dropdown', {
    staticClass: "entity__dropdown",
    attrs: {
      "variant": "link",
      "toggle-class": "border-none",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('WarningIconSmall')];
      },
      proxy: true
    }])
  }, _vm._l(_vm.identities, function (identity, index) {
    return _c('div', {
      key: index,
      staticClass: "entity__wrapper"
    }, [_c('h4', {
      staticClass: "entity__title mb-3"
    }, [_vm._v(_vm._s(identity.label))]), _c('p', {
      staticClass: "entity__desc mb-4"
    }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t(((identity.name) + ".desc"))) + " ")])]), _c('b-button', {
      staticClass: "entity__button",
      attrs: {
        "variant": "outline-primary"
      },
      on: {
        "click": function ($event) {
          return _vm.handleChooseIdentity(identity.name);
        }
      }
    }, [_vm._v(" " + _vm._s(_vm.$t(((identity.name) + ".continue"))) + " ")])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex flex-row align-items-center",
    staticStyle: {
      "gap": "12px"
    }
  }, _vm._l(_vm.identities, function (item, index) {
    return _c('span', {
      key: index,
      staticClass: "entity__option",
      class: _vm.identity === item.name ? 'entity__option--selected' : '',
      on: {
        "click": function ($event) {
          return _vm.handleChooseIdentity(item.name);
        }
      }
    }, [_vm._v(_vm._s(item.label))]);
  }), 0)], 1), _c('b-modal', {
    attrs: {
      "id": "entity-notification",
      "centered": "",
      "hide-footer": "",
      "hide-header": "",
      "no-close-on-backdrop": "",
      "no-close-on-esc": ""
    }
  }, [_c('div', {
    staticClass: "d-none d-md-block"
  }, [_c('p', {
    staticClass: "text-right mb-0"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_c('CircleCloseIcon')], 1)]), _c('p', {
    staticClass: "mb-4 notification-title"
  }, [_vm._v(" " + _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.notification-title"))) + " ")]), _c('p', {
    staticClass: "mb-4 notification-body",
    domProps: {
      "innerHTML": _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.notification-body")))
    }
  }), _c('p', {
    staticClass: "text-center mb-0"
  }, [_c('b-button', {
    staticClass: "notification-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.changeIdentity(_vm.selecting_identity, function () {
          _vm.$router.go(0);
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.confirm"))) + " ")])], 1)]), _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('p', {
    staticClass: "mb-4 notification-title"
  }, [_vm._v(" " + _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.notification-title"))) + " ")]), _c('p', {
    staticClass: "mb-4 notification-body",
    domProps: {
      "innerHTML": _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.notification-body")))
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-center align-items-centers",
    staticStyle: {
      "gap": "20px"
    }
  }, [_c('b-button', {
    staticClass: "notification-button notification__button--outline",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": _vm.closeModal
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.close"))) + " ")]), _c('b-button', {
    staticClass: "notification-button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.changeIdentity(_vm.selecting_identity, function () {
          _vm.$router.go(0);
        });
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.$t(((_vm.selecting_identity) + ".entity-notification.confirm"))) + " ")])], 1)])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }