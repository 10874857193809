var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "181",
      "height": "462",
      "viewBox": "0 0 181 462",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M29.6091 446.116C29.7642 444.543 29.4898 442.955 28.5396 441.762C28.2836 441.422 27.9699 441.124 27.6081 440.86C25.9609 439.684 23.8253 439.246 21.8497 439.115C19.9893 439.004 18.1338 439.266 16.3615 439.788C14.6606 440.287 12.9728 441.111 11.4931 442.207L16.1651 444.622L14.9711 450.253L19.4911 449.299L19.1764 451.971L21.5374 452.176L21.6956 458.222C25.7822 455.619 29.1301 450.92 29.6091 446.116Z",
      "stroke": "#ED6400",
      "stroke-width": "0.711521",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M30.8395 225.135C30.6745 225.004 30.4887 224.895 30.2798 224.809C29.3436 224.425 28.2543 224.459 27.2747 224.631C26.3611 224.79 25.4843 225.132 24.678 225.594C22.2245 227.001 20.2503 230.007 21.4047 232.868C21.6612 233.502 22.0588 234.077 22.5544 234.548C24.4031 236.3 27.1096 236.015 28.9471 234.409C30.8983 232.701 32.2177 229.749 31.8635 227.141C31.7573 226.349 31.4404 225.611 30.8395 225.135Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M11.8395 10.1355C11.6745 10.0036 11.4887 9.89541 11.2798 9.80884C10.3436 9.42465 9.25433 9.45912 8.2747 9.63139C7.36114 9.79034 6.4843 10.1321 5.67795 10.5942C3.22453 12.0006 1.25025 15.0069 2.40475 17.8677C2.66123 18.5025 3.05878 19.0765 3.55442 19.548C5.40307 21.3002 8.10963 21.0153 9.94705 19.4088C11.8983 17.7009 13.2177 14.7493 12.8635 12.1411C12.7573 11.349 12.4404 10.6112 11.8395 10.1355Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M124.899 229.008L124.783 225.838L119.693 227.739L120.066 220.968L114.19 218.968C110.861 222.309 108.883 226.945 110.385 231.705C110.867 233.239 111.694 234.671 112.77 235.882C116.784 240.365 123.189 240.24 127.788 236.834C128.195 236.537 128.583 236.22 128.961 235.873L127.685 228.854L124.899 229.008Z",
      "stroke": "#ED6400",
      "stroke-width": "0.546803",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M75.8995 19.0083L75.7834 15.8384L70.6928 17.7393L71.0662 10.9683L65.1903 8.96846C61.8614 12.3093 59.8829 16.9448 61.3849 21.705C61.8665 23.2389 62.6938 24.6711 63.7699 25.8818C67.7838 30.3649 74.1891 30.2399 78.7878 26.8345C79.195 26.5372 79.5827 26.2199 79.961 25.8729L78.6852 18.8543L75.8995 19.0083Z",
      "stroke": "#ED6400",
      "stroke-width": "0.546803",
      "stroke-miterlimit": "10"
    }
  }), _c('path', {
    attrs: {
      "d": "M94.8395 449.135C94.6745 449.004 94.4887 448.895 94.2798 448.809C93.3436 448.425 92.2543 448.459 91.2747 448.631C90.3611 448.79 89.4843 449.132 88.678 449.594C86.2245 451.001 84.2503 454.007 85.4047 456.868C85.6612 457.502 86.0588 458.077 86.5544 458.548C88.4031 460.3 91.1096 460.015 92.9471 458.409C94.8983 456.701 96.2177 453.749 95.8635 451.141C95.7573 450.349 95.4404 449.611 94.8395 449.135Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M176.84 3.13548C176.674 3.00359 176.489 2.89541 176.28 2.80884C175.344 2.42465 174.254 2.45912 173.275 2.63139C172.361 2.79034 171.484 3.13212 170.678 3.59422C168.225 5.00065 166.25 8.00695 167.405 10.8677C167.661 11.5025 168.059 12.0765 168.554 12.548C170.403 14.3002 173.11 14.0153 174.947 12.4088C176.898 10.7009 178.218 7.74929 177.863 5.1411C177.757 4.34904 177.44 3.61117 176.84 3.13548Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }