var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "22",
      "height": "23",
      "viewBox": "0 0 22 23",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M11.0026 18.832C15.0527 18.832 18.3359 15.5488 18.3359 11.4987C18.3359 7.44861 15.0527 4.16536 11.0026 4.16536C6.95252 4.16536 3.66927 7.44861 3.66927 11.4987C3.66927 15.5488 6.95252 18.832 11.0026 18.832ZM11.0026 20.6654C16.0652 20.6654 20.1693 16.5613 20.1693 11.4987C20.1693 6.43609 16.0652 2.33203 11.0026 2.33203C5.93999 2.33203 1.83594 6.43609 1.83594 11.4987C1.83594 16.5613 5.93999 20.6654 11.0026 20.6654Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.9948 6.91602C10.4885 6.91602 10.0781 7.32642 10.0781 7.83268C10.0781 8.33894 10.4885 8.74935 10.9948 8.74935C11.5011 8.74935 11.9115 8.33894 11.9115 7.83268C11.9115 7.32642 11.5011 6.91602 10.9948 6.91602ZM10.9948 10.5827C10.4885 10.5827 10.0781 10.9931 10.0781 11.4993V15.166C10.0781 15.6723 10.4885 16.0827 10.9948 16.0827C11.5011 16.0827 11.9115 15.6723 11.9115 15.166V11.4993C11.9115 10.9931 11.5011 10.5827 10.9948 10.5827Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }