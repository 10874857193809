var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M1.66536 2.5C2.1256 2.5 2.4987 2.8731 2.4987 3.33333V15C2.4987 15.4602 2.87179 15.8333 3.33203 15.8333H18.332C18.7923 15.8333 19.1654 16.2064 19.1654 16.6667C19.1654 17.1269 18.7923 17.5 18.332 17.5H3.33203C1.95132 17.5 0.832031 16.3807 0.832031 15V3.33333C0.832031 2.8731 1.20513 2.5 1.66536 2.5Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17.0814 4.27596C17.481 4.5043 17.6199 5.01334 17.3915 5.41294L14.7361 10.06C14.1448 11.0946 12.9055 11.5682 11.7749 11.1913L9.17365 10.3242C8.84243 10.2138 8.47751 10.3216 8.2594 10.5942L5.65206 13.8534C5.36455 14.2128 4.84014 14.2711 4.48075 13.9835C4.12137 13.696 4.0631 13.1716 4.35061 12.8122L6.95796 9.55306C7.61226 8.73518 8.70705 8.41187 9.7007 8.74309L12.302 9.61018C12.6788 9.7358 13.0919 9.57796 13.289 9.23306L15.9445 4.58604C16.1728 4.18644 16.6819 4.04761 17.0814 4.27596Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }