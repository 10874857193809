var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "6",
      "height": "10",
      "viewBox": "0 0 6 10",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M0.792894 0.792893C0.402369 1.18342 0.402369 1.81658 0.792894 2.20711L3.58579 5L0.792894 7.79289C0.402369 8.18342 0.402369 8.81658 0.792894 9.20711C1.18342 9.59763 1.81658 9.59763 2.20711 9.20711L5.70711 5.70711C6.09763 5.31658 6.09763 4.68342 5.70711 4.29289L2.20711 0.792893C1.81658 0.402369 1.18342 0.402369 0.792894 0.792893Z",
      "fill": "#777E91"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }