var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.0003 16.668C13.6822 16.668 16.667 13.6832 16.667 10.0013C16.667 6.3194 13.6822 3.33464 10.0003 3.33464C6.31843 3.33464 3.33366 6.3194 3.33366 10.0013C3.33366 13.6832 6.31843 16.668 10.0003 16.668ZM10.0003 18.3346C14.6027 18.3346 18.3337 14.6037 18.3337 10.0013C18.3337 5.39893 14.6027 1.66797 10.0003 1.66797C5.39795 1.66797 1.66699 5.39893 1.66699 10.0013C1.66699 14.6037 5.39795 18.3346 10.0003 18.3346Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M9.99935 5.83203C9.53911 5.83203 9.16602 6.20513 9.16602 6.66536C9.16602 7.1256 9.53911 7.4987 9.99935 7.4987C10.4596 7.4987 10.8327 7.1256 10.8327 6.66536C10.8327 6.20513 10.4596 5.83203 9.99935 5.83203ZM9.99935 9.16536C9.53911 9.16536 9.16602 9.53846 9.16602 9.9987V13.332C9.16602 13.7923 9.53911 14.1654 9.99935 14.1654C10.4596 14.1654 10.8327 13.7923 10.8327 13.332V9.9987C10.8327 9.53846 10.4596 9.16536 9.99935 9.16536Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }