var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M14.582 12.5007C14.2052 12.5007 13.6728 12.5527 13.1185 12.6286C12.8596 12.6641 12.6622 12.8616 12.6267 13.1205C12.5508 13.6748 12.4987 14.2072 12.4987 14.584C12.4987 14.9608 12.5508 15.4932 12.6267 16.0475C12.6622 16.3064 12.8596 16.5039 13.1185 16.5393C13.6728 16.6152 14.2052 16.6673 14.582 16.6673C14.9588 16.6673 15.4913 16.6152 16.0455 16.5393C16.3045 16.5039 16.5019 16.3064 16.5374 16.0475C16.6133 15.4932 16.6654 14.9608 16.6654 14.584C16.6654 14.2072 16.6133 13.6748 16.5374 13.1205C16.5019 12.8616 16.3045 12.6641 16.0455 12.6286C15.4913 12.5527 14.9588 12.5007 14.582 12.5007ZM12.8924 10.9774C11.8925 11.1143 11.1124 11.8944 10.9754 12.8943C10.8969 13.4679 10.832 14.0908 10.832 14.584C10.832 15.0772 10.8969 15.7001 10.9754 16.2736C11.1124 17.2735 11.8925 18.0536 12.8924 18.1906C13.4659 18.2691 14.0888 18.334 14.582 18.334C15.0752 18.334 15.6982 18.2691 16.2717 18.1906C17.2716 18.0536 18.0517 17.2735 18.1886 16.2736C18.2672 15.7001 18.332 15.0772 18.332 14.584C18.332 14.0908 18.2672 13.4679 18.1886 12.8943C18.0517 11.8944 17.2716 11.1143 16.2717 10.9774C15.6982 10.8988 15.0752 10.834 14.582 10.834C14.0888 10.834 13.4659 10.8988 12.8924 10.9774Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.41797 12.5007C5.04115 12.5007 4.50874 12.5527 3.95448 12.6286C3.69554 12.6641 3.49809 12.8616 3.46263 13.1205C3.38672 13.6748 3.33464 14.2072 3.33464 14.584C3.33464 14.9608 3.38672 15.4932 3.46263 16.0475C3.49809 16.3064 3.69554 16.5039 3.95448 16.5393C4.50874 16.6152 5.04115 16.6673 5.41797 16.6673C5.79479 16.6673 6.3272 16.6152 6.88146 16.5393C7.1404 16.5039 7.33784 16.3064 7.37331 16.0475C7.44921 15.4932 7.5013 14.9608 7.5013 14.584C7.5013 14.2072 7.44921 13.6748 7.37331 13.1205C7.33784 12.8616 7.1404 12.6641 6.88146 12.6286C6.3272 12.5527 5.79479 12.5007 5.41797 12.5007ZM3.72833 10.9774C2.72841 11.1143 1.94832 11.8944 1.81138 12.8943C1.73283 13.4679 1.66797 14.0908 1.66797 14.584C1.66797 15.0772 1.73283 15.7001 1.81138 16.2736C1.94832 17.2735 2.72841 18.0536 3.72833 18.1906C4.30184 18.2691 4.92477 18.334 5.41797 18.334C5.91117 18.334 6.5341 18.2691 7.10761 18.1906C8.10752 18.0536 8.88762 17.2735 9.02456 16.2736C9.1031 15.7001 9.16797 15.0772 9.16797 14.584C9.16797 14.0908 9.1031 13.4679 9.02456 12.8943C8.88762 11.8944 8.10752 11.1143 7.10761 10.9774C6.5341 10.8988 5.91117 10.834 5.41797 10.834C4.92477 10.834 4.30184 10.8988 3.72833 10.9774Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M14.582 3.33268C14.2052 3.33268 13.6728 3.38477 13.1185 3.46068C12.8596 3.49614 12.6622 3.69359 12.6267 3.95252C12.5508 4.50679 12.4987 5.0392 12.4987 5.41602C12.4987 5.79283 12.5508 6.32524 12.6267 6.87951C12.6622 7.13845 12.8596 7.33589 13.1185 7.37135C13.6728 7.44726 14.2052 7.49935 14.582 7.49935C14.9588 7.49935 15.4913 7.44726 16.0455 7.37135C16.3045 7.33589 16.5019 7.13845 16.5374 6.87951C16.6133 6.32524 16.6654 5.79283 16.6654 5.41602C16.6654 5.0392 16.6133 4.50679 16.5374 3.95252C16.5019 3.69359 16.3045 3.49614 16.0455 3.46068C15.4913 3.38477 14.9588 3.33268 14.582 3.33268ZM12.8924 1.80943C11.8925 1.94637 11.1124 2.72646 10.9754 3.72638C10.8969 4.29989 10.832 4.92282 10.832 5.41602C10.832 5.90921 10.8969 6.53214 10.9754 7.10565C11.1124 8.10557 11.8925 8.88566 12.8924 9.02261C13.4659 9.10115 14.0888 9.16602 14.582 9.16602C15.0752 9.16602 15.6982 9.10115 16.2717 9.02261C17.2716 8.88566 18.0517 8.10557 18.1886 7.10565C18.2672 6.53214 18.332 5.90921 18.332 5.41602C18.332 4.92282 18.2672 4.29989 18.1886 3.72638C18.0517 2.72646 17.2716 1.94637 16.2717 1.80943C15.6982 1.73088 15.0752 1.66602 14.582 1.66602C14.0888 1.66602 13.4659 1.73088 12.8924 1.80943Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M5.41797 3.33268C5.04115 3.33268 4.50874 3.38477 3.95448 3.46068C3.69554 3.49614 3.49809 3.69359 3.46263 3.95252C3.38672 4.50679 3.33464 5.0392 3.33464 5.41602C3.33464 5.79283 3.38672 6.32524 3.46263 6.87951C3.49809 7.13845 3.69554 7.33589 3.95448 7.37135C4.50874 7.44726 5.04115 7.49935 5.41797 7.49935C5.79479 7.49935 6.3272 7.44726 6.88146 7.37135C7.1404 7.33589 7.33784 7.13845 7.37331 6.87951C7.44921 6.32524 7.5013 5.79283 7.5013 5.41602C7.5013 5.0392 7.44921 4.50679 7.37331 3.95252C7.33784 3.69359 7.1404 3.49614 6.88146 3.46068C6.3272 3.38477 5.79479 3.33268 5.41797 3.33268ZM3.72833 1.80943C2.72841 1.94637 1.94832 2.72646 1.81138 3.72638C1.73283 4.29989 1.66797 4.92282 1.66797 5.41602C1.66797 5.90921 1.73283 6.53214 1.81138 7.10565C1.94832 8.10557 2.72841 8.88566 3.72833 9.02261C4.30184 9.10115 4.92477 9.16602 5.41797 9.16602C5.91117 9.16602 6.5341 9.10115 7.10761 9.02261C8.10752 8.88566 8.88762 8.10557 9.02456 7.10565C9.1031 6.53214 9.16797 5.90921 9.16797 5.41602C9.16797 4.92282 9.1031 4.29989 9.02456 3.72638C8.88762 2.72646 8.10752 1.94637 7.10761 1.80943C6.5341 1.73088 5.91117 1.66602 5.41797 1.66602C4.92477 1.66602 4.30184 1.73088 3.72833 1.80943Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }