var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "account-types "
  }, [_vm._m(0), _c('div', {
    staticClass: "account-types__detail"
  }, [_c('p', {
    staticClass: "account-types__detail--header roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.account-types.title")) + " ")]), _c('p', {
    staticClass: "account-types__detail--body roboto"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.account-types.content")) + " ")]), _c('p', {
    staticClass: "account-types__detail--option-header"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.account-types.subtitle")) + ": ")]), _vm._l(_vm.$t('home.account-types.benefits'), function (ben) {
    return _c('div', {
      staticClass: "account-types__detail--option-body"
    }, [_c('CheckRoundedIcon', {
      staticStyle: {
        "width": "25px",
        "height": "25px"
      }
    }), _c('p', {
      staticClass: "account-types__detail--option-title"
    }, [_vm._v(_vm._s(ben))])], 1);
  }), _c('b-button', {
    staticClass: "account-types__detail--button",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$router.push('/accounts-and-fees');
      }
    }
  }, [_c('p', {
    staticClass: "account-types__detail--button-detail"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.account-types.learn-more")) + " ")])])], 2)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bg-primary text-center text-md-right"
  }, [_c('img', {
    staticClass: "ill",
    attrs: {
      "src": require("../svg/illu.svg")
    }
  })]);
}]

export { render, staticRenderFns }