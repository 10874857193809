var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "19",
      "height": "19",
      "viewBox": "0 0 19 19",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.3506 7.84467C12.0577 7.55178 11.5829 7.55178 11.29 7.84467L9.19531 9.93934L7.10064 7.84467C6.80775 7.55178 6.33288 7.55178 6.03998 7.84467C5.74709 8.13756 5.74709 8.61244 6.03998 8.90533L8.66498 11.5303C8.95788 11.8232 9.43275 11.8232 9.72564 11.5303L12.3506 8.90533C12.6435 8.61244 12.6435 8.13756 12.3506 7.84467Z",
      "fill": "#39454C"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }