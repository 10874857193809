var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "24",
      "height": "24",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12 19.9961C16.4183 19.9961 20 16.4144 20 11.9961C20 7.57782 16.4183 3.99609 12 3.99609C7.58172 3.99609 4 7.57782 4 11.9961C4 16.4144 7.58172 19.9961 12 19.9961ZM12 21.9961C17.5228 21.9961 22 17.5189 22 11.9961C22 6.47325 17.5228 1.99609 12 1.99609C6.47715 1.99609 2 6.47325 2 11.9961C2 17.5189 6.47715 21.9961 12 21.9961Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12 7C11.4477 7 11 7.44772 11 8C11 8.55229 11.4477 9 12 9C12.5523 9 13 8.55229 13 8C13 7.44772 12.5523 7 12 7ZM12 11C11.4477 11 11 11.4477 11 12V16C11 16.5523 11.4477 17 12 17C12.5523 17 13 16.5523 13 16V12C13 11.4477 12.5523 11 12 11Z",
      "fill": "#ED6400"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }