<template>
  <div class="section market" ref="yay">
    <h2>
      {{ $t('components.market trend.market trends') }}
    </h2>

    <div class="market-buttons mb-5">
      <b-button
        v-for="market in marketTrendsTypes.filter(
          (market) => nowUrl == '' || !market.onlyFrontPage
        )"
        :key="market.name"
        :variant="marketType == market.name ? 'success' : 'outline-success'"
        @click="goToMarket(market.name)"
        :class="[
          'market-button',
          marketType == market.name ? 'marketTrendsButtonActive' : 'marketTrendsButton',
          { 'light-theme-title': marketType !== market.name }
        ]"
        ><p class="body-paragraph-bold">
          {{ $t(market.i18nText) }}
        </p></b-button
      >
    </div>

    <div class="d-md-none" style="margin-bottom: 32px">
      <select v-model="marketType" class="caption-bold">
        <option
          v-for="market in marketTrendsTypes.filter(
            (market) => nowUrl == '' || !market.onlyFrontPage
          )"
          :key="market.name"
          :value="market.name">
          {{ $t(market.i18nText) }}
        </option>
      </select>
    </div>

    <div v-if="loadTable && currentMarket">
      <MarketTable
        :key="currentMarket.name"
        :showFull="showFull"
        :nowUrl="nowUrl"
        :symbols="currentSymbols"
        :exampleSymbols="currentMarket.exampleSymbols">
      </MarketTable>
    </div>
    <div class="text-center text-success my-2" v-else>
      <b-spinner class="align-middle"></b-spinner>
    </div>

    <div
      class="disclosure d-flex align-items-center justify-content-between"
      style="color: #39454c; text-decoration: none; height: 64px !important">
      <div v-b-modal.riskDisclosure class="d-flex align-items-center">
        <RiskDisclosureIcon class="d-none d-md-block" style="margin-right: 4px" />
        <RiskDisclosureIconMobile class="d-md-none" style="margin-right: 4px" />
        <h4 class="light-theme-title risk">
          {{ $t('components.market trend.risk disclosure.title') }}
        </h4>
      </div>
      <div
        v-if="nowUrl !== '' && marketType !== 'Newest trends'"
        class="d-flex align-items-center justify-content-center expand"
        @click="showFull = !showFull">
        <h4 v-if="!showFull" class="light-theme-title">
          {{ $t('components.market trend.expand') }}
        </h4>
        <h4 v-else class="light-theme-title">
          {{ $t('components.market trend.hide') }}
        </h4>
        <RightChevron style="margin-left: 9.5px" />
      </div>
    </div>
    <div v-if="!nowUrl" class="d-flex justify-content-center vm-but w-100" style="margin-top: 64px">
      <router-link
        v-if="marketType !== 'Newest trends'"
        style="margin: auto"
        class="link w-100 d-flex justify-content-center"
        :to="{
          name: 'Markets',
          query: { marketType },
          params: { showFull: true }
        }">
        <b-button variant="outline-primary" class="round-btn-16-24-outline vm-but vm">
          <p class="button-small-to-medium text-nowrap">
            {{ $t('components.market trend.view more') }}
          </p>
        </b-button>
      </router-link>
    </div>
    <div v-if="nowUrl == 'markets' && marketType !== 'Newest trends'">
      <div class="d-md-flex justify-content-center learnMore">
        <a
          class="link"
          :href="
            marketType == 'Crypto'
              ? `https://nozax.kb.help/${marketType.toLowerCase()}-trading-with-nozax/`
              : `https://nozax.kb.help/what-is-${
                  marketType == 'Shares' ? 'stocks' : marketType.toLowerCase()
                }-trading/`
          "
          target="_blank"
          style="color: inherit; text-decoration: none">
          <b-button variant="outline-primary" class="round-btn-12-16-outline">
            <p class="button-small">
              {{
                $t('components.market trend.learn more about') +
                ' ' +
                $t(`components.market trend.${marketType.toLowerCase()}`)
              }}
            </p></b-button
          ></a
        >
      </div>
    </div>
    <div v-if="nowUrl == 'markets' && marketType === 'Newest trends'" style="height: 144px"></div>
    <b-modal
      id="riskDisclosure"
      size="lg"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-backdrop="true"
      :no-close-on-esc="true">
      <span
        @click="$bvModal.hide('riskDisclosure')"
        class="position-absolute close-icon d-flex justify-content-center align-items-center">
        <CloseIcon />
      </span>
      <div>
        <h1 id="title">
          {{ $t('components.market trend.risk disclosure.title') }}
        </h1>
        <p id="part">
          <span style="font-weight: 700 !important">{{
            $t('components.market trend.risk disclosure.p1')
          }}</span
          >&nbsp;{{ $t('components.market trend.risk disclosure.p2') }}
        </p>
        <br />
        <p id="part1">{{ $t('components.market trend.risk disclosure.p3') }}</p>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CloseIcon from '@/components/icons/CloseIcon.vue';
import RightChevron from './images-and-svgs/RighChevron.vue';
import RiskDisclosureIcon from './images-and-svgs/RiskDisclosureIcon.vue';
import RiskDisclosureIconMobile from './images-and-svgs/RiskDisclosureIconMobile.vue';
import Vue from 'vue';
import { INSPECT_MAX_BYTES } from 'buffer';
import { mapActions, mapGetters } from 'vuex';
// import marketTrendsTypes, { exampleSymbols } from "./marketTrendsTypes";
import MarketTable from './MarketTable.vue';

export default {
  props: ['marketTypeFromHome', 'showFullFromHome'],
  components: {
    RiskDisclosureIcon,
    RiskDisclosureIconMobile,
    CloseIcon,
    RightChevron,
    MarketTable
  },

  data() {
    return {
      fetchingConfig: false,
      loadTable: false,
      showFull: false,
      marketType: '',
      selected: 'all',
      a: 20
    };
  },

  computed: {
    ...mapGetters({
      getSiteSettings: 'getSiteSettings',
      allSymbols: 'symbols',
      identity: 'identity',
      init_identity_done: 'init_identity_done'
    }),
    exampleSymbols() {
      return this.identity?.frontpage_symbols;
    },
    marketTrendsTypes() {
      if (!this.exampleSymbols) return [];
      return [
        {
          type: 'newest_trends',
          name: 'Newest trends',
          i18nText: 'components.market trend.newest trends',
          onlyFrontPage: true,
          component: 'NewestTrends',
          exampleSymbols: this.exampleSymbols.newest_trends,
          checkSymbol: (symbol) => {
            return this.exampleSymbols.newest_trends.includes(symbol.symbol);
          }
        },
        {
          type: 'forex',
          name: 'Forex',
          i18nText: 'components.market trend.forex',
          component: 'Forex',
          exampleSymbols: this.exampleSymbols.forex,
          checkSymbol: (symbol) => {
            return symbol.site_setting?.frontpage_market == 'Forex';
          }
        },
        {
          type: 'shares',
          name: 'Shares',
          i18nText: 'components.market trend.shares',
          component: 'Shares',
          exampleSymbols: this.exampleSymbols.shares,
          checkSymbol: (symbol) => {
            return symbol.site_setting?.frontpage_market == 'Shares';
          }
        },
        {
          type: 'indices',
          name: 'Indices',
          i18nText: 'components.market trend.indices',
          component: 'Indices',
          exampleSymbols: this.exampleSymbols.indices,
          checkSymbol: (symbol) => {
            return symbol.site_setting?.frontpage_market == 'Indices';
          }
        },
        {
          type: 'commodities',
          name: 'Commodities',
          i18nText: 'components.market trend.commodities',
          component: 'Commodities',
          exampleSymbols: this.exampleSymbols.commodities,
          checkSymbol: (symbol) => {
            return symbol.site_setting?.frontpage_market == 'Commodities';
          }
        }
      ];
    },
    currentMarket() {
      return this.marketTrendsTypes.find((market) => market.name == this.marketType);
    },
    currentSymbols() {
      return this.allSymbols.filter((symbol) => {
        if (!this.showFull) {
          return this.currentMarket?.exampleSymbols?.includes(symbol.symbol);
        }
        return this.currentMarket?.checkSymbol(symbol);
      });
    },
    chartSymbols() {
      return Object.values(this.exampleSymbols ?? {}).reduce((prev, cur) => prev.concat(cur), []);
    },
    nowUrl() {
      return this.$route.path.split('/')[1];
    }
  },

  methods: {
    ...mapActions([
      'fetchSymbolHistory',
      'getSymbolConfigs',
      'getAllSymbolConfigs',
      'getSymbolsDescription',
      'getTradingPlatforms'
    ]),
    goToMarket(type) {
      if (this.nowUrl !== '')
        history.pushState({}, null, `${this.$route.path}?marketType=${encodeURIComponent(type)}`);

      this.marketType = type;
      this.showFull = false;

      this.$emit('setMetaData', type);
      this.rewatch();
    },

    async fetchSymbols() {
      if (!this.init_identity_done) return;
      this.loadTable = false;
      this.fetchingConfig = true;
      let {
        data: { configs }
      } = await this.getSymbolConfigs({
        symbols: this.currentSymbols.map((item) => item.symbol)
      });
      configs.forEach(async (item, index) => {
        if (this.chartSymbols.includes(item.symbol)) {
          const res = await this.fetchSymbolHistory({ symbol: item.symbol });
        }
      });
      this.loadTable = true;
      this.fetchingConfig = false;
    },

    rewatch() {
      this.$cable.perform({
        channel: 'SymbolChannel',
        action: 'update_watchlist',
        data: {
          symbols: this.currentSymbols.map((item) => item.symbol).join(',')
        }
      });
    },

    async init() {
      await this.getAllSymbolConfigs();
      await this.getSymbolsDescription();
      this.$cable.subscribe({
        channel: 'SymbolChannel',
        symbols: this.currentSymbols.map((item) => item.symbol).join(',')
      });
      await this.fetchSymbols();
    }
  },

  async mounted() {
    if (this.marketTypeFromHome) {
      this.marketType = this.marketTypeFromHome;
    } else if (this.$route.query.marketType) {
      this.marketType = this.$route.query.marketType;
    } else if (this.nowUrl != '') {
      this.marketType = 'Forex';
    } else {
      this.marketType = 'Newest trends';
    }

    if (this.showFullFromHome) {
      this.showFull = true;
    }
    if (this.init_identity_done) {
      this.init();
    }
  },

  channels: {
    SymbolChannel: {
      connected() {
        console.log('Connected to the SymbolChannel');
        this.rewatch();
      },
      rejected() {
        console.log('Rejected to the SymbolChannel');
      },
      received(symbol) {
        if (this.fetchingConfig) return;
        this.$store.commit('LIVE_UPDATE', { symbol });
      },
      disconnected() {
        console.log('Disconnected to the SymbolChannel');
      }
    }
  },
  watch: {
    '$route.query.marketType': {
      handler: function (marketType) {
        this.marketType = marketType;
      },
      deep: true,
      immediate: true
    },
    marketType() {
      this.fetchSymbols();
    },
    init_identity_done: {
      handler: async function (init_identity_done) {
        console.log(init_identity_done);
        if (init_identity_done) {
          await this.init();
        }
      },
      deep: true,
      immediate: true
    },
    showFull() {
      this.fetchSymbols();
      this.rewatch();
    }
  }
};
</script>

<style lang="scss" scoped>
::v-deep .table {
  margin-bottom: 0 !important;
  @media screen and (max-width: 767px) {
    /* margin-bottom: 32px !important; */
  }

  @media screen and (min-width: 768px) {
    /* margin-bottom: 64px !important; */
  }
}
.market-buttons {
  margin-top: 40px;
  display: flex;
  /* gap: 18.6px; */
  /* grid-template-columns: repeat(6, 1fr); */
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
  @media screen and (max-width: 1199px) {
    gap: 12.4px;
  }
  @media screen and (min-width: 1200px) {
    gap: 18.6px;
    margin-top: 54px;
  }
  .market-button {
    width: 100%;
    min-height: 50px;
  }
}
.fixed-width-tablet {
  @media (min-width: 768px) and (max-width: 1199px) {
    width: 150px !important;
  }
}

::v-deep .modal-content {
  max-width: 465px;
  justify-content: center;
  margin: 40% auto !important;
  width: 465px;
  height: 310px;
  padding: 36px 40px !important;
  /* /Gray / White */

  background: #ffffff;
  /* Stroke/light */

  border: 1px solid #dee2e6;
  /* Shadow / Small */

  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 20px 20px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  position: relative;
}
::v-deep .modal-body {
  padding: 0 !important;
  @media (max-width: 767px) {
    width: 269px !important;
    height: 209px !important;
  }
}
select {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  padding-right: 15px;
  background-image: url('data:image/svg+xml; utf8, <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.2071 9.79289C15.8166 9.40237 15.1834 9.40237 14.7929 9.79289L12 12.5858L9.20711 9.79289C8.81658 9.40237 8.18342 9.40237 7.79289 9.79289C7.40237 10.1834 7.40237 10.8166 7.79289 11.2071L11.2929 14.7071C11.6834 15.0976 12.3166 15.0976 12.7071 14.7071L16.2071 11.2071C16.5976 10.8166 16.5976 10.1834 16.2071 9.79289Z" fill="%2339454C"/></svg>');
  background-repeat: no-repeat;
  background-position: calc(100% - 12px) center;
  background-size: 24px;
  border-style: inset;
  outline-color: #07b53b;

  background-color: #fcfcfd;
  padding: 12px;
  border-radius: 12px;
  height: 48px;
  vertical-align: middle;
  border: 2px solid #07b53b;
}
.caption-bold {
  line-height: 20px;
}

.vm-but {
  @media (max-width: 767px) {
    margin-top: 32px !important;
    width: 100% !important;
  }
  @media (min-width: 768px) {
    width: 168px;
    height: 50px !important;
  }
}
.vm {
  @media (max-width: 767px) {
    /* margin-top: 16px; */
  }
  @media (min-width: 768px) {
    /* margin-top: 52px; */
  }
}
.round-btn-12-16 {
  min-width: 146px !important;
  height: 48px !important;
}
.round-btn-12-16-outline {
  height: 48px !important;
  width: 270px !important;
  @media (max-width: 767px) {
    width: 100% !important;
  }
}
h2 {
  @media (max-width: 767px) {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;

    color: #39454c;
    margin-bottom: 32px !important;
  }
}
h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  /* identical to box height, or 120% */

  letter-spacing: -0.01em;

  /* Neutrals/2 */

  color: #23262f;
  @media (max-width: 767px) {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 120%;
    /* or 38px */
    margin-bottom: 32px !important;
  }
}
::v-deep .modal-content {
  @media (max-width: 767px) {
    margin-top: 30vh !important;
    width: 341px !important;
    height: 350px !important;
    padding: 50px 35px !important;
  }
}

#title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;
  text-align: center;

  color: #484c57;
  margin-bottom: 32px !important;
  @media (max-width: 767px) {
    margin-bottom: 16px !important;
  }
}
#part,
#part1 {
  margin: 0 !important;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  /* or 24px */

  color: #888f94;
  @media (max-width: 767px) {
    text-align: center !important;
  }
}
.section {
  @media (min-width: 1200px) {
    // max-width: 865px !important;
    margin: auto !important;
    padding: 0 !important;
  }
  @media (min-width: 992px) and (max-width: 1199px) {
    max-width: 865px !important;
    margin: auto !important;
    padding: 0 !important;
  }
}
h4 {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 150%;
  margin: 0 !important;
  @media (max-width: 767px) {
    font-size: 12px;
  }
}

.learnMore {
  margin-bottom: 96px;
  @media (max-width: 767px) {
    margin-top: 32px;
  }
  @media (min-width: 768px) {
    margin-top: 64px;
  }
}

.expand:hover {
  cursor: pointer;
}
.expand {
  @media (min-width: 768px) {
    // width: 200px !important;
    padding-right: 8px !important;
  }
}

.body-paragraph-bold {
  white-space: nowrap;
}
</style>
