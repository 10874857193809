var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.97172 10.8346C3.55145 10.8346 3.19695 11.1476 3.14482 11.5646L2.72816 14.8979C2.66599 15.3953 3.05381 15.8346 3.55506 15.8346H8.11245C8.6137 15.8346 9.00152 15.3953 8.93935 14.8979L8.52268 11.5646C8.47055 11.1476 8.11605 10.8346 7.69578 10.8346H3.97172ZM1.49103 11.3579C1.64741 10.1068 2.71091 9.16797 3.97172 9.16797H7.69578C8.95659 9.16797 10.0201 10.1068 10.1765 11.3579L10.5931 14.6912C10.7797 16.1833 9.61619 17.5013 8.11245 17.5013H3.55506C2.05131 17.5013 0.887845 16.1833 1.07436 14.6912L1.49103 11.3579Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M12.3038 10.8346C11.8835 10.8346 11.529 11.1476 11.4769 11.5646L11.0602 14.8979C10.998 15.3953 11.3858 15.8346 11.8871 15.8346H16.4445C16.9457 15.8346 17.3335 15.3953 17.2714 14.8979L16.8547 11.5646C16.8026 11.1476 16.4481 10.8346 16.0278 10.8346H12.3038ZM9.82306 11.3579C9.97944 10.1068 11.0429 9.16797 12.3038 9.16797H16.0278C17.2886 9.16797 18.3521 10.1068 18.5085 11.3579L18.9252 14.6912C19.1117 16.1833 17.9482 17.5013 16.4445 17.5013H11.8871C10.3833 17.5013 9.21988 16.1833 9.40639 14.6912L9.82306 11.3579Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M8.13969 4.16764C7.71942 4.16764 7.36492 4.48059 7.31279 4.89761L6.89613 8.23095C6.83395 8.72833 7.22178 9.16764 7.72302 9.16764H12.2804C12.7817 9.16764 13.1695 8.72833 13.1073 8.23095L12.6906 4.89761C12.6385 4.48059 12.284 4.16764 11.8638 4.16764H8.13969ZM5.659 4.69089C5.81538 3.43982 6.87888 2.50098 8.13969 2.50098H11.8638C13.1246 2.50098 14.1881 3.43982 14.3444 4.69089L14.7611 8.02422C14.9476 9.51636 13.7842 10.8343 12.2804 10.8343H7.72302C6.21928 10.8343 5.05581 9.51636 5.24233 8.02422L5.659 4.69089Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }