import Vue from "vue";
import VueI18n from "vue-i18n";
import enMessage from "@/libs/i18n/en.json";
import jaMessage from "@/libs/i18n/ja.json";
import esMessage from "@/libs/i18n/es.json";
import idMessage from "@/libs/i18n/id.json";
import loMessage from "@/libs/i18n/lo.json";
import thMessage from "@/libs/i18n/th.json";
import zhMessage from "@/libs/i18n/zh.json";


Vue.use(VueI18n);
let messages = { en: enMessage, ja: jaMessage, es: esMessage, id: idMessage, lo: loMessage, th: thMessage, zh: zhMessage };

const i18n = new VueI18n({
  locale: "en",
  fallbackLocale: "en",
  messages,
});
export default i18n;
