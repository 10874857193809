var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "started section"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row justify-content-center"
  }, [_c('div', {
    staticClass: "col-12 p-0"
  }, [_c('h2', {
    staticClass: "text-center light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.title")) + " ")]), _c('h4', {
    staticClass: "text-center subtitle-bodyLarge text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.sub-title")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.$t("home.get started.sub-title-green")))])]), _c('div', {
    staticClass: "started-items d-flex flex-column flex-md-row justify-content-center align-items-start"
  }, [_c('div', {
    staticClass: "started-item d-flex flex-column justify-content-start align-items-center"
  }, [_c('div', {
    staticClass: "started-icon"
  }, [_c('HomeRegisterIcon')], 1), _c('div', {
    staticClass: "started-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-custom-to-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.register")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular text-grey-primary-main-500",
    attrs: {
      "id": "re"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.register-text")) + " ")])])]), _c('div', {
    staticClass: "started-item d-flex flex-column justify-content-start align-items-center",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('div', {
    staticClass: "started-icon"
  }, [_c('HomeVerifyIcon')], 1), _c('div', {
    staticClass: "started-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-custom-to-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.verify")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular text-grey-primary-main-500",
    attrs: {
      "id": "verify"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.verify-text")) + " ")])])]), _c('div', {
    staticClass: "started-item d-flex flex-column justify-content-start align-items-center",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('div', {
    staticClass: "started-icon"
  }, [_c('HomeFundIcon')], 1), _c('div', {
    staticClass: "started-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-custom-to-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.fund")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular text-grey-primary-main-500",
    attrs: {
      "id": "fund"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.fund-text")) + " ")])])]), _c('div', {
    staticClass: "started-item d-flex flex-column justify-content-start align-items-center",
    staticStyle: {
      "gap": "24px"
    }
  }, [_c('div', {
    staticClass: "started-icon"
  }, [_c('HomeTradeIcon')], 1), _c('div', {
    staticClass: "started-content text-center"
  }, [_c('h6', {
    staticClass: "body-paragraph-bold-to-custom-to-h3 light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.trade")) + " ")]), _c('p', {
    staticClass: "body-small-text-to-mobile-body-regular text-grey-primary-main-500",
    attrs: {
      "id": "tra"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("home.get started.trade-text")) + " ")])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }