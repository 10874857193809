<template>
  <div>
    <div class="d-flex flex-row align-items-center">
      <span class="entity-text">Entity:</span>

      <b-dropdown
        variant="link"
        toggle-class="border-none"
        no-caret
        class="entity__dropdown"
      >
        <template #button-content>
          <WarningIconSmall />
        </template>
        <div
          v-for="(identity, index) in identities"
          :key="index"
          class="entity__wrapper"
        >
          <h4 class="entity__title mb-3">{{ identity.label }}</h4>
          <p class="entity__desc mb-4">
            <span>
              {{ $t(`${identity.name}.desc`) }}
            </span>
          </p>

          <b-button
            @click="handleChooseIdentity(identity.name)"
            class="entity__button"
            variant="outline-primary"
          >
            {{ $t(`${identity.name}.continue`) }}
          </b-button>
        </div>
      </b-dropdown>

      <div class="d-flex flex-row align-items-center" style="gap: 12px">
        <span
          v-for="(item, index) in identities"
          :key="index"
          @click="handleChooseIdentity(item.name)"
          class="entity__option"
          :class="identity === item.name ? 'entity__option--selected' : ''"
          >{{ item.label }}</span
        >
      </div>
    </div>

    <b-modal
      id="entity-notification"
      centered
      hide-footer
      hide-header
      no-close-on-backdrop
      no-close-on-esc
    >
      <div class="d-none d-md-block">
        <p class="text-right mb-0">
          <span @click="closeModal" style="cursor: pointer">
            <CircleCloseIcon />
          </span>
        </p>
        <p class="mb-4 notification-title">
          {{ $t(`${selecting_identity}.entity-notification.notification-title`) }}
        </p>
        <p
          class="mb-4 notification-body"
          v-html="$t(`${selecting_identity}.entity-notification.notification-body`)"
        ></p>
        <p class="text-center mb-0">
          <b-button
            variant="primary"
            class="notification-button"
            @click="changeIdentity(selecting_identity, () => {$router.go(0)})"
          >
            {{ $t(`${selecting_identity}.entity-notification.confirm`) }}
          </b-button>
        </p>
      </div>
      <div class="d-block d-md-none">
        <p class="mb-4 notification-title">
          {{ $t(`${selecting_identity}.entity-notification.notification-title`) }}
        </p>
        <p
          class="mb-4 notification-body"
          v-html="$t(`${selecting_identity}.entity-notification.notification-body`)"
        ></p>
        <div
          class="d-flex justify-content-center align-items-centers"
          style="gap: 20px"
        >
          <b-button
            @click="closeModal"
            variant="outline-primary"
            class="notification-button notification__button--outline"
          >
            {{ $t(`${selecting_identity}.entity-notification.close`) }}
          </b-button>
          <b-button
            @click="changeIdentity(selecting_identity, () => {$router.go(0)})"
            variant="primary"
            class="notification-button"
          >
            {{ $t(`${selecting_identity}.entity-notification.confirm`) }}
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CircleCloseIcon from "@/components/icons/CircleCloseIcon.vue";
import WarningIconSmall from "../icons/WarningIconSmall.vue";
import { changeIdentity } from "@/libs/MultipleIdentities.js"

export default {
  components: { CircleCloseIcon, WarningIconSmall },
  data() {
    return {
      identities: [],
      selecting_identity: this.identity,
    };
  },

  mounted() {
    this.identities = JSON.parse(localStorage.getItem("APP_ENV")).sort(
      (a, b) => a.order - b.order
    );
  },
  computed: {
    identity() {
      return localStorage.getItem("APP_IDENTITY")
    }
  },
  methods: {
    changeIdentity,
    async handleChooseIdentity(name) {
      if (name == this.identity)
        return;
      let obj = this.identities.find(i => i.name == name);
      if (obj.isRequiredPopup) {
        this.selecting_identity = name
        await this.$nextTick()
        this.$bvModal.show("entity-notification");
      } else {
        this.changeIdentity(name, () => {this.$router.go(0)})
      }
    },
    closeModal() {
      this.$bvModal.hide("entity-notification");
    }
  },
  watch: {
    identity() {
      this.setIdentity(this.identity);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .btn:focus {
  box-shadow: none !important;
}
::v-deep .dropdown-menu {
  background: #ffffff;
  border: 1px solid #e6e8ec;
  box-shadow: 0px 16px 64px -48px rgba(31, 47, 70, 0.15);
  border-radius: 8px;
  padding: 24px 16px;

  @media screen and (min-width: 992px) {
    gap: 40px;
  }
}

.entity-text {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  color: #333e44;
  letter-spacing: -0.01em;
}

.entity__option {
  //font-family: "Open Sans";
  font-style: normal;
  padding: 10px 2px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
}
.entity__option--selected {
  border-bottom: 2px solid #07b53b;
}

.entity__title {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-bottom: 16px;
}
.entity__desc {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #616a70;
  margin-bottom: 24px;

  @media screen and (min-width: 992px) {
    width: 230px;
  }
}

.entity__button {
  border: 2px solid #07b53b;
  border-radius: 90px;
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  text-align: center;
  letter-spacing: 0.02em;
  width: 230px;
  height: 48px;
  color: #39454c;
}

.entity__wrapper:not(:last-child) {
  margin-bottom: 40px;
}
::v-deep .vs__dropdown-toggle {
  border: 0;
  height: unset;
  width: 180px;
  height: 28px;
}
::v-deep .vs__search {
  width: 0;
  height: 0;
}
::v-deep .vs__selected-options {
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
}

::v-deep .vs__selected {
  text-transform: uppercase;
  font-weight: 600;
  font-size: 16px;
  @media screen and(max-width: 744px) {
    font-size: 14px;
  }
}
::v-deep .vs__selected-option {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 16px;
  @media screen and(max-width: 744px) {
    font-size: 14px;
  }
}

::v-deep .vs__selected::after {
  content: url('data:image/svg+xml; utf8,<svg width="11" height="7" viewBox="0 0 11 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.4083 0.633539C9.95268 0.177927 9.21399 0.177927 8.75838 0.633539L5.5 3.89191L2.24162 0.633539C1.78601 0.177927 1.04732 0.177927 0.591709 0.633539C0.136096 1.08915 0.136096 1.82784 0.591708 2.28345L4.67504 6.36679C5.13065 6.8224 5.86935 6.8224 6.32496 6.36679L10.4083 2.28345C10.8639 1.82784 10.8639 1.08915 10.4083 0.633539Z" fill="%23484C57"/></svg>');
  margin-left: 8px;
  margin-bottom: 4px;
}
::v-deep .vs__dropdown-menu {
  position: absolute;
  top: 30px;
  left: 0;
  min-width: unset;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin-top: 8px;
  background: #ffffff;
  box-shadow: 0 0 0 2px #e6e8ec;
  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -khtml-border-radius: 12px;
  border-top: 8px solid #ffffff;
  border-bottom: 8px solid #ffffff;
}

::v-deep .vs__actions {
  display: none;
}
::v-deep .modal-content {
  border-radius: 20px;
  padding: 24px 32px;
}
::v-deep .modal-body {
  padding: 0;
}
.notification-title {
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  color: #484c57;
}

.notification-body {
  //font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #484c57;
}

.notification-button {
  border-radius: 90px;
  padding: 16px 24px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #fcfcfd;
  font-weight: 700;
  font-size: 16px;
  width: 148px;
  line-height: 16px;
}
@media screen and (max-width: 744px) {
  ::v-deep .modal-content {
    padding: 24px;
  }
  .notification__button--outline {
    color: var(--success);
    transition: all 0.3s ease-in-out;
    :hover {
      color: #fcfcfd;
    }
  }
}
.entity__dropdown ::v-deep .btn-link {
  padding-left: 4px !important;
  padding-right: 8px !important;
}
</style>
