<template>
   <div @mouseover="hover = true"
    @mouseleave="hover = false">
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M10.0013 16.666C13.6832 16.666 16.668 13.6813 16.668 9.99935C16.668 6.31745 13.6832 3.33268 10.0013 3.33268C6.3194 3.33268 3.33464 6.31745 3.33464 9.99935C3.33464 13.6813 6.3194 16.666 10.0013 16.666ZM10.0013 18.3327C14.6036 18.3327 18.3346 14.6017 18.3346 9.99935C18.3346 5.39697 14.6036 1.66602 10.0013 1.66602C5.39893 1.66602 1.66797 5.39697 1.66797 9.99935C1.66797 14.6017 5.39893 18.3327 10.0013 18.3327Z" :fill="hover ? '#07B53B' : '#212529'"/>
<path d="M10.0013 8.33398C10.0013 7.87375 10.3744 7.50065 10.8346 7.50065H11.668C12.1282 7.50065 12.5013 7.12756 12.5013 6.66732C12.5013 6.20708 12.1282 5.83398 11.668 5.83398H10.8346C9.45389 5.83398 8.33464 6.95328 8.33464 8.33398V10.0007H7.5013C7.04107 10.0007 6.66797 10.3737 6.66797 10.834C6.66797 11.2942 7.04106 11.6673 7.5013 11.6673H8.33464V16.6673C8.33464 17.1276 8.70772 17.5007 9.16797 17.5007C9.62822 17.5007 10.0013 17.1276 10.0013 16.6673V11.6673H11.668C12.1282 11.6673 12.5013 11.2942 12.5013 10.834C12.5013 10.3737 12.1282 10.0007 11.668 10.0007H10.0013V8.33398Z" :fill="hover ? '#07B53B' : '#212529'"/>
</svg>
</div>
</template>
<script>export default {
  data() {
    return {
      hover: false,
    };
  }
}
</script>