var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "contain": "paint"
    },
    attrs: {
      "id": "app"
    }
  }, [_vm.$route.name !== 'LegalDocument' || !_vm.$route.params.document ? _c('div', [_c('div', {
    ref: "risk_warning",
    staticClass: "risk-warning__container",
    staticStyle: {
      "box-shadow": "inset 0 -1px 0 0 #e6e8ec"
    }
  }, [_c('div', {
    staticClass: "d-flex risk-warning__wrapper"
  }, [_c('span', {
    staticClass: "riskWarning"
  }, [_vm._v(" " + _vm._s(_vm.$t("nav.risk-warning")) + " ")])])]), _c('Nav', {
    staticClass: "w-100 bg-white",
    staticStyle: {
      "position": "sticky !important",
      "top": "0px",
      "z-index": "999"
    },
    attrs: {
      "langs": _vm.langs,
      "fontMapping": _vm.fontMapping,
      "languageFlags": _vm.languageFlags
    }
  }), _c('div', {
    staticClass: "dynamicMarginTop",
    class: _vm.getContainerClass(_vm.$route.name)
  }, [_c('div', {
    class: !['home', 'AboutUs'].includes(_vm.$route.name) ? 'row' : ''
  }, [_c('div', {
    class: !['home', 'AboutUs'].includes(_vm.$route.name) ? 'col-12 p-0' : ''
  }, [_c('router-view', {
    attrs: {
      "identity": _vm.identity
    }
  })], 1)])]), _vm.api_url ? _c('Footer') : _vm._e()], 1) : _c('div', [_c('router-view', {
    attrs: {
      "identity": _vm.identity
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }