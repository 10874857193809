import Vue from 'vue'
import Vuex from 'vuex'
import symbolModule from './symbolModule'
import settingsModule from './settingsModule'
import axios from "axios";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    identity: null,
    api_url: null,
    portal_url: null,
    init_identity_done: false
  },
  getters: {
    identity(state) {
      return state.identity
    },
    api_url(state) {
      return state.api_url
    },
    portal_url(state) {
      return state.portal_url
    },
    init_identity_done(state) {
      return state.init_identity_done
    }
  },
  mutations: {
    INIT_IDENTITY(state, identity) {
      state.identity = identity
      state.api_url = identity.api_url
      state.portal_url = identity.portal_url
      state.init_identity_done = true
    }
  },
  actions: {
    setIdentity({commit}, identity) {
      Object.defineProperty(Vue.prototype, "$axios", {
        value: axios.create({
          baseURL: identity.api_url,
        }),
        writable: true,
      });
      Object.defineProperty(Vue.prototype, "$api_url", {
        value: identity.api_url,
        writable: true,
      });
      Object.defineProperty(Vue.prototype, "$portal_url", {
        value: identity.portal_url,
        writable: true,
      });
      Object.defineProperty(Vue.prototype, "$identity", {
        value: identity,
        writable: true,
      });
      commit("INIT_IDENTITY", identity)
    }
  },
  modules: {
    symbol: symbolModule,
    settings: settingsModule
  }
})
