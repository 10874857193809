var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    on: {
      "mouseover": function ($event) {
        _vm.hover = true;
      },
      "mouseleave": function ($event) {
        _vm.hover = false;
      }
    }
  }, [_c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10 5.00065C7.15276 5.00065 4.81132 5.10128 3.17656 5.20252C2.45673 5.24709 1.88937 5.80228 1.83224 6.53522C1.74303 7.67954 1.66667 8.96798 1.66667 10.0007C1.66667 11.0333 1.74303 12.3217 1.83224 13.4661C1.88937 14.1991 2.45673 14.7542 3.17656 14.7988C4.81131 14.9 7.15276 15.0007 10 15.0007C12.8472 15.0007 15.1887 14.9 16.8234 14.7988C17.5433 14.7542 18.1106 14.1991 18.1677 13.4661C18.257 12.3217 18.3333 11.0333 18.3333 10.0007C18.3333 8.96798 18.257 7.67954 18.1677 6.53522C18.1106 5.80228 17.5433 5.24709 16.8234 5.20252C15.1887 5.10128 12.8472 5.00065 10 5.00065ZM3.07355 3.53903C1.5175 3.63539 0.291777 4.85138 0.170614 6.40568C0.0804294 7.5626 0 8.90223 0 10.0007C0 11.0991 0.0804294 12.4387 0.170614 13.5956C0.291777 15.1499 1.5175 16.3659 3.07355 16.4622C4.73785 16.5653 7.11394 16.6673 10 16.6673C12.8861 16.6673 15.2622 16.5653 16.9264 16.4622C18.4825 16.3659 19.7083 15.1499 19.8294 13.5956C19.9196 12.4387 20 11.0991 20 10.0007C20 8.90223 19.9196 7.5626 19.8294 6.40568C19.7083 4.85138 18.4825 3.63539 16.9264 3.53903C15.2622 3.43598 12.8861 3.33398 10 3.33398C7.11394 3.33398 4.73785 3.43598 3.07355 3.53903Z",
      "fill": _vm.hover ? '#07B53B' : '#212529'
    }
  }), _c('path', {
    attrs: {
      "d": "M9.62761 7.53003L12.292 9.3063C12.7868 9.63614 12.7868 10.3631 12.292 10.6931L9.62761 12.4692C9.07386 12.8385 8.33203 12.4415 8.33203 11.7759V8.2234C8.33203 7.55783 9.07386 7.16084 9.62761 7.53003Z",
      "fill": _vm.hover ? '#07B53B' : '#212529'
    }
  })])]);
}
var staticRenderFns = []

export { render, staticRenderFns }