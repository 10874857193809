var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-table', {
    staticClass: "table table-borderless table-text align-middle",
    attrs: {
      "items": _vm.computed_symbols_data,
      "fields": _vm.fields,
      "thead-class": "header table-header",
      "tbody-tr-class": "body-row",
      "thead-tr-class": "head-row",
      "primary-key": "symbol"
    },
    scopedSlots: _vm._u([{
      key: "cell(symbol-icon)",
      fn: function (_ref) {
        var item = _ref.item;
        return [_c('b-img', {
          staticClass: "icon mr-symbol",
          attrs: {
            "src": _vm.getIconForSymbol(item)
          }
        })];
      }
    }, {
      key: "cell(symbol-name)",
      fn: function (_ref2) {
        var item = _ref2.item;
        return [_c('div', {
          staticClass: "d-flex flex-column justify-content-center"
        }, [item.group.startsWith('CFDs on Stocks') || item.group.startsWith('Indices') ? _c('span', {
          staticClass: "truncate"
        }, [_vm._v(" " + _vm._s(item.long_name) + " ")]) : _c('span', {
          staticClass: "truncate"
        }, [_vm._v(" " + _vm._s(item.symbol) + " ")]), item.group.startsWith('CFDs on Stocks') || item.group.startsWith('Indices') ? _c('span', {
          staticClass: "small text-muted"
        }, [_vm._v(" " + _vm._s(item.symbol) + " ")]) : _vm._e()])];
      }
    }, {
      key: "cell(chartData)",
      fn: function (data) {
        return [_c('Chart', {
          attrs: {
            "chartData": data.value
          }
        })];
      }
    }, {
      key: "cell(configuration.SwapLong)",
      fn: function (data) {
        return [_c('span', [_vm._v(" " + _vm._s((+data.value).toFixed(2)) + " ")])];
      }
    }, {
      key: "cell(configuration.SwapShort)",
      fn: function (data) {
        return [_c('span', [_vm._v(" " + _vm._s((+data.value).toFixed(2)) + " ")])];
      }
    }, {
      key: "cell(Bid)",
      fn: function (data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.value.toString()) + " ")])];
      }
    }, {
      key: "cell(Ask)",
      fn: function (data) {
        return [_c('span', [_vm._v(" " + _vm._s(data.value.toString()) + " ")])];
      }
    }, {
      key: "cell(Spread)",
      fn: function (data) {
        return [_c('span', [_vm._v(" " + _vm._s((+data.value).toString()) + " ")])];
      }
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }