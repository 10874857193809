var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ready section text-center"
  }, [_c('h2', {
    staticClass: "light-theme-title"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.ready to get started.title")) + " ")]), _c('div', {
    staticClass: "ready-buttons d-flex flex-column flex-md-row justify-content-center align-items-center"
  }, [_vm.portal_url ? _c('b-button', {
    staticClass: "ready-button yes ready-button round-btn-16-24 yes",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "text-white-button button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.ready to get started.signup")) + " ")])]) : _vm._e(), _c('a', {
    staticClass: "link ready-button",
    attrs: {
      "href": "https://nozax.kb.help",
      "target": "_blank"
    }
  }, [_c('b-button', {
    staticClass: "ready-button round-btn-16-24-outline",
    attrs: {
      "variant": "outline-primary"
    }
  }, [_c('p', {
    staticClass: "button-medium question"
  }, [_vm._v(" " + _vm._s(_vm.$t("components.ready to get started.more info")) + " ")])])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }