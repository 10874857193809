var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('g', {
    attrs: {
      "clip-path": "url(#clip0_773_41195)"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M3.87298 2.55183C4.94875 1.47605 6.72792 1.6025 7.64075 2.8196L9.15597 4.8399C9.90245 5.8352 9.80347 7.22794 8.92374 8.10766L7.8066 9.2248C7.91486 9.5066 8.27127 10.125 9.3566 11.2103C10.4419 12.2957 11.0603 12.6521 11.3421 12.7603L12.4593 11.6432C13.339 10.7635 14.7317 10.6645 15.727 11.411L17.7473 12.9262C18.9644 13.839 19.0909 15.6182 18.0151 16.694C17.6654 17.0437 17.6062 17.1029 17.0225 17.6866C16.4277 18.2814 15.1646 18.8374 13.8859 18.893C11.8848 18.98 9.16663 18.0914 5.82107 14.7459C2.47551 11.4003 1.58695 8.68217 1.67396 6.68107C1.72236 5.5679 2.07627 4.34529 2.88505 3.54907C3.46405 2.96076 3.53934 2.88546 3.87298 2.55183ZM3.33905 6.75346C3.27874 8.14058 3.86333 10.4311 6.99958 13.5674C10.1358 16.7036 12.4264 17.2882 13.8135 17.2279C15.1084 17.1716 15.803 16.5459 15.844 16.508L16.8366 15.5155C17.1952 15.1569 17.153 14.5638 16.7473 14.2595L14.727 12.7443C14.3953 12.4955 13.931 12.5285 13.6378 12.8217C13.196 13.2635 12.8944 13.5696 12.304 14.1577C11.0776 15.3794 8.97841 13.1892 8.17809 12.3889C7.44261 11.6534 5.20122 9.48748 6.40801 8.26638C6.4104 8.26398 6.73544 7.93894 7.74523 6.92915C8.03847 6.63591 8.07146 6.17167 7.82264 5.8399L6.30741 3.8196C6.00314 3.4139 5.41008 3.37175 5.05149 3.73034C4.72144 4.06038 4.34793 4.4339 4.0599 4.72392C3.46709 5.32083 3.37078 6.0238 3.33905 6.75346Z",
      "fill": "#07B53B"
    }
  })]), _c('defs', [_c('clipPath', {
    attrs: {
      "id": "clip0_773_41195"
    }
  }, [_c('rect', {
    attrs: {
      "width": "20",
      "height": "20",
      "fill": "white",
      "transform": "translate(0 0.591797)"
    }
  })])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }