var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "21",
      "viewBox": "0 0 20 21",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M2.5 4.75814C2.5 2.91719 3.99238 1.4248 5.83333 1.4248H12.786C13.67 1.4248 14.5179 1.77599 15.143 2.40112L16.5237 3.78183C17.1488 4.40695 17.5 5.25479 17.5 6.13885V16.4248C17.5 18.2658 16.0076 19.7581 14.1667 19.7581H5.83333C3.99238 19.7581 2.5 18.2658 2.5 16.4248V4.75814ZM15.8333 7.25814V16.4248C15.8333 17.3453 15.0871 18.0915 14.1667 18.0915H5.83333C4.91286 18.0915 4.16667 17.3453 4.16667 16.4248V4.75814C4.16667 3.83766 4.91286 3.09147 5.83333 3.09147H11.6667V4.75814C11.6667 6.13885 12.786 7.25814 14.1667 7.25814H15.8333ZM15.7409 5.59147C15.659 5.35614 15.5247 5.13987 15.3452 4.96034L13.9645 3.57963C13.7849 3.4001 13.5687 3.26576 13.3333 3.18392V4.75814C13.3333 5.21837 13.7064 5.59147 14.1667 5.59147H15.7409Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M13.0906 10.0029C13.416 10.3283 13.416 10.8559 13.0906 11.1814L9.75722 14.5147C9.43179 14.8401 8.90415 14.8401 8.57871 14.5147L6.91205 12.848C6.58661 12.5226 6.58661 11.995 6.91205 11.6695C7.23748 11.3441 7.76512 11.3441 8.09056 11.6695L9.16797 12.7469L11.912 10.0029C12.2375 9.67743 12.7651 9.67743 13.0906 10.0029Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }