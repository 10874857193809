var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "720",
      "height": "616",
      "viewBox": "0 0 720 616",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "720",
      "height": "616",
      "fill": "#07B53B"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "436.445",
      "cy": "272.077",
      "r": "154.492",
      "fill": "#07B53B"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "546.675",
      "cy": "406.607",
      "r": "85.9253",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "495.572",
      "cy": "172.264",
      "r": "19.9625",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "460.784",
      "cy": "240.761",
      "r": "6.00615",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "377.431",
      "cy": "351.928",
      "r": "14.7549",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "358.607",
      "cy": "294.049",
      "r": "6.08679",
      "stroke": "white",
      "stroke-width": "2.25949"
    }
  }), _c('circle', {
    attrs: {
      "cx": "595.552",
      "cy": "370.427",
      "r": "5.71021",
      "stroke": "white",
      "stroke-width": "3.01266"
    }
  }), _c('circle', {
    attrs: {
      "cx": "429.72",
      "cy": "100.217",
      "r": "6.08679",
      "stroke": "white",
      "stroke-width": "2.25949"
    }
  }), _c('path', {
    attrs: {
      "d": "M321.193 328.887H262C262 321.339 267.031 314.919 274.036 312.571C275.787 304.802 282.971 298.982 291.568 298.982C300.152 298.982 307.329 304.789 309.087 312.546C316.13 314.876 321.193 321.315 321.193 328.887Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M326.511 319.28H293.168C293.168 315.071 296.002 311.492 299.948 310.182C300.934 305.851 304.98 302.606 309.823 302.606C314.659 302.606 318.701 305.844 319.691 310.169C323.659 311.468 326.511 315.057 326.511 319.28Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M302.793 126.263C319.692 148.706 364.579 188.524 408.938 168.245C464.387 142.897 533.302 165.869 519.044 235.576C504.785 305.283 326.557 304.491 354.281 443.114C382.006 581.736 574.064 524.658 583.569 378.114",
      "stroke": "white",
      "stroke-width": "2.06359",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.13 4.13"
    }
  }), _c('rect', {
    attrs: {
      "x": "287.901",
      "y": "100.687",
      "width": "89.3216",
      "height": "107.097",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.33333"
    }
  }), _c('rect', {
    attrs: {
      "x": "311.862",
      "y": "93.9479",
      "width": "40.601",
      "height": "12.8944",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.33333"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "410.41",
      "cy": "227.523",
      "rx": "6.93776",
      "ry": "6.93853",
      "fill": "white"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "536.518",
      "cy": "289.496",
      "rx": "7.47144",
      "ry": "7.47227",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "337.295",
      "cy": "259.425",
      "r": "4.99075",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "525.873",
      "cy": "456.102",
      "r": "6.09981",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "437.346",
      "cy": "365.836",
      "r": "6.09981",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M388.735 426.961H315.805C315.805 417.661 322.003 409.751 330.634 406.858C332.792 397.286 341.642 390.116 352.234 390.116C362.811 390.116 371.654 397.271 373.819 406.828C382.497 409.698 388.735 417.631 388.735 426.961Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M643.386 254.807H538C538 241.202 546.957 229.631 559.429 225.398C562.547 211.395 575.336 200.907 590.642 200.907C605.926 200.907 618.703 211.373 621.833 225.354C634.373 229.554 643.386 241.158 643.386 254.807Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "418.089",
      "y": "275.701",
      "width": "65.9279",
      "height": "36.5454",
      "rx": "3.51117",
      "transform": "rotate(-12.4135 418.089 275.701)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.23199"
    }
  }), _c('rect', {
    attrs: {
      "x": "428.815",
      "y": "278.202",
      "width": "65.9279",
      "height": "36.5454",
      "rx": "3.51117",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.23199"
    }
  }), _c('rect', {
    attrs: {
      "x": "428.449",
      "y": "292.828",
      "width": "67.1433",
      "height": "3.69596",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "475.07",
      "y": "283.532",
      "width": "16.79",
      "height": "5.24686",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.195 124.527H346.387",
      "stroke": "#07B53B",
      "stroke-width": "3.99999",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.051 135.194H346.384",
      "stroke": "#07B53B",
      "stroke-width": "3.99999",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.195 149.948H346.387",
      "stroke": "#07B53B",
      "stroke-width": "3.99999",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M301.051 160.615H328.25",
      "stroke": "#07B53B",
      "stroke-width": "3.99999",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "539.544",
      "cy": "397.745",
      "r": "32.3636",
      "stroke": "#07B53B",
      "stroke-width": "2.6038"
    }
  }), _c('path', {
    attrs: {
      "d": "M561.414 422.854L576.586 438.026",
      "stroke": "#07B53B",
      "stroke-width": "2.6038",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M573.16 434.601L588.333 449.773",
      "stroke": "#07B53B",
      "stroke-width": "8.67933",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M525.52 398.152L535.141 407.774L554.385 388.53",
      "stroke": "#07B53B",
      "stroke-width": "2.6038",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }