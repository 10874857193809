var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p-0"
  }, [_c('Hero'), _c('MarketTrends', {
    staticClass: "homeMarketTrends"
  }), _c('TradingTime')], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('AccountTypes')], 1), _c('div', {
    staticClass: "container"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 p-0"
  }, [_c('Partners'), _c('GetStarted'), _c('AreYouReady', {
    staticClass: "a"
  })], 1)])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }