<template>
  <div id="chart">
    <apexchart
      width="160"
      height="36"
      type="area"
      :options="chartOptions"
      :series="series"
      class="d-flex justify-content-end my-chart"
    ></apexchart>
  </div>
</template>
<style scoped></style>

<script>
export default {
  props: { chartData: { type: Array, default: () => [] } },
  created() {
    // setInterval(() => {
    //   this.series[0].data.filter(
    //     (data) =>
    //       new Date().getTime() - new Date(data.LastTick).getTime() > 1000 * 24 * 3600
    //       && (new Date(data.LastTick).getMinutes() == 0 || new Date(data.LastTick).getMinutes() == 30)
    //   );
    // }, 5000);
  },
  computed: {
    filtered_data() {
      this.chartData.sort(
        (a, b) => a.LastTick.getTime() - b.LastTick.getTime()
      );
      let res = this.chartData.filter((data) => {
        return (
          new Date().getTime() - data.LastTick.getTime() < 1000 * 24 * 3600
        );
      });
      // console.log(this.chartData)
      let tmp = [];
      res.forEach((item) => {
        if (
          tmp.length == 0 ||
          item.LastTick.getTime() - tmp[tmp.length - 1].LastTick.getTime() >=
            1000 * 30 * 60
        ) {
          tmp.push(item);
        }
      });
      return tmp;
    },

    chart_data() {
      if (this.filtered_data.length < 2) {
        return [1, 1];
      }
      let min_point = Math.min(...this.filtered_data.map((x) => x.value));
      let max_point = Math.max(...this.filtered_data.map((x) => x.value));
      return this.filtered_data.map(
        (data) => ((data.value - min_point) / (max_point - min_point)) * 100
      );
    },

    series() {
      // console.log(this.filtered_data)
      return [
        {
          data: this.chart_data,
        },
      ];
    },
  },
  data() {
    return {
      chartOptions: {
        tooltip: {
          enabled: false,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: false,
        },
        // noData: {
        //   text: "no data",
        //   align: 'center',
        //   verticalAlign: 'middle',
        //   offsetX: 0,
        //   offsetY: 0,
        //   style: {
        //     color: "#07b53b"
        //   }
        // },
        hover: { mode: null },
        chart: {
          type: "area",
          height: "100",
          sparkline: {
            enabled: true,
          },
        },
        stroke: {
          curve: "smooth",
          color: "#07b53b",
          width: 2,
        },
        colors: ["#07b53b"],

        xaxis: {
          crosshairs: {
            width: 1,
          },
        },
        yaxis: {
          min: 0,
          max: 100,
        },
      },
    };
  },
};
</script>
