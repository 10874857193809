var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hero-custom"
  }, [_c('div', {
    staticClass: "hero-custom__content mx-auto"
  }, [_c('h1', {
    staticClass: "hero-custom__title"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.header")) + " "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.$t("home.hero.header-motion")))])]), _c('h2', {
    staticClass: "hero-custom__text hero-subtitle text-grey-primary-main-500"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.sub-header")) + " ")]), _c('div', {
    staticClass: "hero-buttons d-none d-md-flex"
  }, [_vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium text-white-button",
    class: _vm.$i18n.locale == 'en' ? 'width160' : ''
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.signup")) + " ")])]) : _vm._e(), _vm.portal_url ? _c('b-button', {
    staticClass: "round-btn-16-24-outline align-middle",
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.try demo")) + " ")])]) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-none d-xl-block"
  }, [_c('HomeHero')], 1), _c('div', {
    staticClass: "d-none d-md-block d-xl-none"
  }, [_c('HomeTabletHero')], 1), _c('div', {
    staticClass: "d-block d-md-none"
  }, [_c('HomeMobileHero')], 1), _c('div', {
    staticClass: "d-block d-md-none mx-auto w-100",
    staticStyle: {
      "gap": "14px",
      "padding": "32px",
      "margin-bottom": "23px"
    }
  }, [_vm.portal_url ? _c('b-button', {
    staticClass: "d-block mx-auto round-btn-16-24 w-100",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium text-white-button"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.signup")) + " ")])]) : _vm._e(), _vm.portal_url ? _c('b-button', {
    staticClass: "w-100 round-btn-16-24-outline d-block mx-auto",
    staticStyle: {
      "margin-top": "14px"
    },
    attrs: {
      "variant": "outline-primary"
    },
    on: {
      "click": function ($event) {
        return _vm.goToSignUp();
      }
    }
  }, [_c('p', {
    staticClass: "button-medium text-nowrap"
  }, [_vm._v(" " + _vm._s(_vm.$t("home.hero.try demo")) + " ")])]) : _vm._e()], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }