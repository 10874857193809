<template>
  <div class="ready section text-center">
    <h2 class="light-theme-title">
      {{ $t("components.ready to get started.title") }}
    </h2>
    <div
      class="ready-buttons d-flex flex-column flex-md-row justify-content-center align-items-center"
    >
      <b-button
        v-if="portal_url"
        variant="primary"
        @click="goToSignUp()"
        class="ready-button yes ready-button round-btn-16-24 yes"
      >
        <p class="text-white-button button-medium">
          {{ $t("components.ready to get started.signup") }}
        </p>
      </b-button>
      <a class="link ready-button" href="https://nozax.kb.help" target="_blank">
        <b-button
          variant="outline-primary"
          class="ready-button round-btn-16-24-outline"
        >
          <p class="button-medium question">
            {{ $t("components.ready to get started.more info") }}
          </p>
        </b-button>
      </a>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";


export default {
  methods: {
    goToSignUp() {
      if (this.portal_url)
        window.location.href = `${this.portal_url}/register`;
    },
  },
  computed: {
    ...mapGetters(["portal_url"]),
  },
};
</script>
<style lang="scss" scoped>
.ready {
  .ready-buttons {
    gap: 24px;
    .ready-button {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }
}
@media (max-width: 767px) {
  h2 {
    margin-bottom: 24px !important;
    max-width: 273px;
    margin-right: auto;
    margin-left: auto;
  }
}
@media (min-width: 768px) {
  h2 {
    margin-bottom: 48px !important;
  }
}
</style>
