var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "720",
      "height": "616",
      "viewBox": "0 0 720 616",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('rect', {
    attrs: {
      "width": "720",
      "height": "616",
      "fill": "#07B53B"
    }
  }), _c('g', {
    staticStyle: {
      "mix-blend-mode": "multiply"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "341",
      "cy": "265.326",
      "r": "178",
      "fill": "#07B53B"
    }
  })]), _c('circle', {
    attrs: {
      "cx": "468",
      "cy": "420.326",
      "r": "99",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "409.121",
      "cy": "150.325",
      "r": "23",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "369.041",
      "cy": "229.245",
      "r": "6.92007",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "273",
      "cy": "357.326",
      "r": "17",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "251.315",
      "cy": "290.641",
      "r": "7.01298",
      "stroke": "white",
      "stroke-width": "2.6033"
    }
  }), _c('circle', {
    attrs: {
      "cx": "524.315",
      "cy": "378.641",
      "r": "6.57909",
      "stroke": "white",
      "stroke-width": "3.47107"
    }
  }), _c('circle', {
    attrs: {
      "cx": "333.244",
      "cy": "67.3146",
      "r": "7.01298",
      "stroke": "white",
      "stroke-width": "2.6033"
    }
  }), _c('path', {
    attrs: {
      "d": "M208.2 330.782H140C140 322.085 145.796 314.688 153.868 311.982C155.885 303.031 164.162 296.326 174.067 296.326C183.958 296.326 192.227 303.017 194.252 311.954C202.367 314.638 208.2 322.057 208.2 330.782Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M214.326 319.712H175.91C175.91 314.863 179.175 310.739 183.722 309.231C184.858 304.24 189.52 300.502 195.1 300.502C200.671 300.502 205.329 304.232 206.469 309.215C211.041 310.712 214.326 314.848 214.326 319.712Z",
      "fill": "white"
    }
  }), _c('path', {
    attrs: {
      "d": "M187 97.3252C206.47 123.184 258.187 169.06 309.296 145.696C373.183 116.491 452.584 142.958 436.156 223.272C419.728 303.586 214.38 302.674 246.323 462.389C278.266 622.105 499.548 556.341 510.5 387.499",
      "stroke": "white",
      "stroke-width": "2.3776",
      "stroke-linecap": "round",
      "stroke-dasharray": "4.76 4.76"
    }
  }), _c('rect', {
    attrs: {
      "x": "169.842",
      "y": "67.8599",
      "width": "102.913",
      "height": "123.393",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.53621"
    }
  }), _c('rect', {
    attrs: {
      "x": "197.452",
      "y": "60.0943",
      "width": "46.779",
      "height": "14.8564",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.53621"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "310.993",
      "cy": "213.994",
      "rx": "7.99343",
      "ry": "7.99432",
      "fill": "white"
    }
  }), _c('ellipse', {
    attrs: {
      "cx": "456.292",
      "cy": "285.396",
      "rx": "8.60831",
      "ry": "8.60927",
      "fill": "white"
    }
  }), _c('circle', {
    attrs: {
      "cx": "226.75",
      "cy": "250.75",
      "r": "5.75016",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "444.028",
      "cy": "477.354",
      "r": "7.02798",
      "fill": "#ED6400"
    }
  }), _c('circle', {
    attrs: {
      "cx": "342.028",
      "cy": "373.354",
      "r": "7.02798",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M286.027 443.778H202C202 433.063 209.142 423.949 219.086 420.615C221.572 409.587 231.769 401.326 243.973 401.326C256.159 401.326 266.347 409.569 268.842 420.58C278.841 423.888 286.027 433.028 286.027 443.778Z",
      "fill": "#ED6400"
    }
  }), _c('path', {
    attrs: {
      "d": "M579.422 245.429H458C458 229.754 468.32 216.422 482.689 211.545C486.282 195.411 501.017 183.327 518.652 183.327C536.261 183.327 550.984 195.386 554.589 211.494C569.037 216.332 579.422 229.703 579.422 245.429Z",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "319.846",
      "y": "269.501",
      "width": "75.9596",
      "height": "42.1063",
      "rx": "4.04544",
      "transform": "rotate(-12.4135 319.846 269.501)",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.41945"
    }
  }), _c('rect', {
    attrs: {
      "x": "332.198",
      "y": "272.383",
      "width": "75.9596",
      "height": "42.1063",
      "rx": "4.04544",
      "fill": "white",
      "stroke": "#ED6400",
      "stroke-width": "1.41945"
    }
  }), _c('rect', {
    attrs: {
      "x": "331.773",
      "y": "289.234",
      "width": "77.3601",
      "height": "4.25835",
      "fill": "#ED6400"
    }
  }), _c('rect', {
    attrs: {
      "x": "385.492",
      "y": "278.524",
      "width": "19.3448",
      "height": "6.04524",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M185.164 95.3262H237.232",
      "stroke": "#07B53B",
      "stroke-width": "4.60864",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M185 107.616H237.231",
      "stroke": "#07B53B",
      "stroke-width": "4.60864",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M185.164 124.61H237.232",
      "stroke": "#07B53B",
      "stroke-width": "4.60864",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M185 136.9H216.339",
      "stroke": "#07B53B",
      "stroke-width": "4.60864",
      "stroke-linecap": "round"
    }
  }), _c('circle', {
    attrs: {
      "cx": "459.788",
      "cy": "410.114",
      "r": "37.2881",
      "stroke": "#07B53B",
      "stroke-width": "3"
    }
  }), _c('path', {
    attrs: {
      "d": "M484.984 439.045L502.465 456.526",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M498.52 452.578L516.001 470.059",
      "stroke": "#07B53B",
      "stroke-width": "10",
      "stroke-linecap": "round"
    }
  }), _c('path', {
    attrs: {
      "d": "M443.629 410.584L454.715 421.67L476.886 399.498",
      "stroke": "#07B53B",
      "stroke-width": "3",
      "stroke-miterlimit": "10",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }