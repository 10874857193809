var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "46",
      "height": "47",
      "viewBox": "0 0 46 47",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M36.418 9.83333H9.58463C7.46754 9.83333 5.7513 11.5496 5.7513 13.6667V32.8333C5.7513 34.9504 7.46754 36.6667 9.58463 36.6667H36.418C38.5351 36.6667 40.2513 34.9504 40.2513 32.8333V13.6667C40.2513 11.5496 38.5351 9.83333 36.418 9.83333ZM9.58463 6C5.35045 6 1.91797 9.43248 1.91797 13.6667V32.8333C1.91797 37.0675 5.35045 40.5 9.58463 40.5H36.418C40.6521 40.5 44.0846 37.0675 44.0846 32.8333V13.6667C44.0846 9.43248 40.6521 6 36.418 6H9.58463Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M24.918 23.2503C24.918 17.9576 29.2086 13.667 34.5013 13.667H42.168C43.2265 13.667 44.0846 14.5251 44.0846 15.5837C44.0846 16.6422 43.2265 17.5003 42.168 17.5003H34.5013C31.3257 17.5003 28.7513 20.0747 28.7513 23.2503C28.7513 26.426 31.3257 29.0003 34.5013 29.0003H42.168C43.2265 29.0003 44.0846 29.8584 44.0846 30.917C44.0846 31.9755 43.2265 32.8337 42.168 32.8337H34.5013C29.2086 32.8337 24.918 28.5431 24.918 23.2503Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M36.4154 23.2497C36.4154 24.3082 35.5572 25.1663 34.4987 25.1663C33.4402 25.1663 32.582 24.3082 32.582 23.2497C32.582 22.1911 33.4402 21.333 34.4987 21.333C35.5572 21.333 36.4154 22.1911 36.4154 23.2497Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }