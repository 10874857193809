var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M4.16667 3.33431C4.16667 2.87407 3.79357 2.50098 3.33333 2.50098C2.8731 2.50098 2.5 2.87407 2.5 3.33431V5.00098C2.5 5.46121 2.8731 5.83431 3.33333 5.83431C3.79357 5.83431 4.16667 5.46121 4.16667 5.00098V3.33431Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M4.16667 10.001C4.16667 9.54074 3.79357 9.16764 3.33333 9.16764C2.8731 9.16764 2.5 9.54074 2.5 10.001V15.001C2.5 15.4612 2.8731 15.8343 3.33333 15.8343C3.79357 15.8343 4.16667 15.4612 4.16667 15.001V10.001Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M4.16536 8.33464V6.66797C4.16536 6.20773 3.79227 5.83464 3.33203 5.83464C2.87179 5.83464 2.4987 6.20773 2.4987 6.66797V8.33464C2.4987 8.79487 2.87179 9.16797 3.33203 9.16797C3.79227 9.16797 4.16536 8.79487 4.16536 8.33464ZM3.33203 4.16797C1.95132 4.16797 0.832031 5.28726 0.832031 6.66797V8.33464C0.832031 9.71535 1.95132 10.8346 3.33203 10.8346C4.71274 10.8346 5.83203 9.71535 5.83203 8.33464V6.66797C5.83203 5.28726 4.71274 4.16797 3.33203 4.16797Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.8346 6.66732C10.8346 6.20708 10.4615 5.83398 10.0013 5.83398C9.54106 5.83398 9.16797 6.20708 9.16797 6.66732V9.16732C9.16797 9.62756 9.54106 10.0007 10.0013 10.0007C10.4615 10.0007 10.8346 9.62756 10.8346 9.16732V6.66732Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M10.8346 15.0007C10.8346 14.5404 10.4615 14.1673 10.0013 14.1673C9.54106 14.1673 9.16797 14.5404 9.16797 15.0007V16.6673C9.16797 17.1276 9.54106 17.5007 10.0013 17.5007C10.4615 17.5007 10.8346 17.1276 10.8346 16.6673V15.0007Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M10.8333 13.334V10.834C10.8333 10.3737 10.4602 10.0007 10 10.0007C9.53976 10.0007 9.16667 10.3737 9.16667 10.834V13.334C9.16667 13.7942 9.53976 14.1673 10 14.1673C10.4602 14.1673 10.8333 13.7942 10.8333 13.334ZM10 8.33398C8.61929 8.33398 7.5 9.45327 7.5 10.834V13.334C7.5 14.7147 8.61929 15.834 10 15.834C11.3807 15.834 12.5 14.7147 12.5 13.334V10.834C12.5 9.45327 11.3807 8.33398 10 8.33398Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.4987 3.33431C17.4987 2.87407 17.1256 2.50098 16.6654 2.50098C16.2051 2.50098 15.832 2.87407 15.832 3.33431V5.00098C15.832 5.46121 16.2051 5.83431 16.6654 5.83431C17.1256 5.83431 17.4987 5.46121 17.4987 5.00098V3.33431Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M17.4987 12.501C17.4987 12.0407 17.1256 11.6676 16.6654 11.6676C16.2051 11.6676 15.832 12.0407 15.832 12.501V15.001C15.832 15.4612 16.2051 15.8343 16.6654 15.8343C17.1256 15.8343 17.4987 15.4612 17.4987 15.001V12.501Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "fill-rule": "evenodd",
      "clip-rule": "evenodd",
      "d": "M17.5013 10.8346V6.66797C17.5013 6.20773 17.1282 5.83464 16.668 5.83464C16.2077 5.83464 15.8346 6.20773 15.8346 6.66797V10.8346C15.8346 11.2949 16.2077 11.668 16.668 11.668C17.1282 11.668 17.5013 11.2949 17.5013 10.8346ZM16.668 4.16797C15.2873 4.16797 14.168 5.28726 14.168 6.66797V10.8346C14.168 12.2153 15.2873 13.3346 16.668 13.3346C18.0487 13.3346 19.168 12.2153 19.168 10.8346V6.66797C19.168 5.28726 18.0487 4.16797 16.668 4.16797Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }