var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": "20",
      "height": "18",
      "viewBox": "0 0 20 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  }, [_c('path', {
    attrs: {
      "d": "M5.70565 8.79641C5.70565 7.46039 5.95781 6.27282 6.46213 5.24607C6.96645 4.21932 7.6749 3.41524 8.56347 2.82145C9.45203 2.24004 10.4847 1.94315 11.6374 1.94315C12.8142 1.94315 13.8348 2.24004 14.7354 2.82145C15.624 3.40286 16.3324 4.21932 16.8367 5.25844C17.3411 6.29757 17.5932 7.47276 17.5932 8.79641C17.5932 8.84589 17.5812 8.89537 17.5812 8.94485H19.6465C19.6465 8.89537 19.6465 8.84589 19.6465 8.79641C19.6465 7.54699 19.4544 6.38416 19.0582 5.30793C18.6619 4.24406 18.1095 3.3039 17.3891 2.51219C16.6686 1.70811 15.8281 1.08958 14.8435 0.656612C13.8708 0.211274 12.8022 0.000976562 11.6254 0.000976562C10.4727 0.000976562 9.404 0.223644 8.43138 0.656612C7.45877 1.10195 6.60622 1.72048 5.88577 2.51219C5.16531 3.31627 4.61295 4.24406 4.22871 5.30793C3.84447 6.37179 3.65234 7.53462 3.65234 8.79641C3.65234 8.84589 3.65234 8.89537 3.65234 8.94485H5.69364C5.71766 8.89537 5.70565 8.84589 5.70565 8.79641Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M13.9289 8.94434C13.9048 10.2061 13.6647 11.3318 13.1724 12.3339C12.6681 13.373 11.9596 14.1771 11.071 14.7708C10.1825 15.3523 9.14982 15.6492 7.97307 15.6492C6.82034 15.6492 5.78768 15.3523 4.89911 14.7708C4.01055 14.1894 3.3021 13.373 2.79778 12.3339C2.30547 11.3442 2.06531 10.2061 2.0413 8.94434H0C0.0120076 10.1443 0.204129 11.2576 0.576366 12.2844C0.96061 13.3482 1.51296 14.2884 2.23342 15.0801C2.95388 15.8842 3.79441 16.5027 4.77904 16.9357C5.75165 17.381 6.82034 17.5913 7.97307 17.5913C9.14982 17.5913 10.2185 17.3687 11.1911 16.9357C12.1637 16.4904 13.0163 15.8718 13.7367 15.0801C14.4572 14.276 15.0095 13.3482 15.4058 12.2844C15.79 11.2576 15.9822 10.1443 15.9942 8.94434H13.9289Z",
      "fill": "#07B53B"
    }
  }), _c('path', {
    attrs: {
      "d": "M14.9158 7.13782C15.4927 7.13782 15.9604 6.65597 15.9604 6.06158C15.9604 5.4672 15.4927 4.98535 14.9158 4.98535C14.3388 4.98535 13.8711 5.4672 13.8711 6.06158C13.8711 6.65597 14.3388 7.13782 14.9158 7.13782Z",
      "fill": "#07B53B"
    }
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }